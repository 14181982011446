import { Customer, Group } from '@busie/utils'
import { makeAutoObservable } from 'mobx'

import { fetchAllGroups, FetchAllGroupsParams } from '@busie/api'
import {
  ErrorMessage,
  validateEmbeddedForm,
  validateForm,
  ValidationResponse,
} from './helpers'
import * as types from './store.types'

const customerFormFieldsInitialValues: types.CustomerFormFields = {
  name: '',
  organization: '',
  groupname: '',
  email: '',
  phoneNumber: '',
}

class CustomerForm {
  selectionMode: types.SelectionMode = 'select_existing_customer'
  editMode = false
  isFormUpdated = false
  customer: Customer | null = null
  customerGroup: Group | null = null
  fields = customerFormFieldsInitialValues
  validation: ValidationResponse = {
    valid: true,
    errors: {},
  }
  groups: Group[] = []
  constructor() {
    makeAutoObservable(this)
    this.setCustomer = this.setCustomer.bind(this)
  }
  public setCustomerSelectionMode(value: types.SelectionMode) {
    this.selectionMode = value
  }
  public setEditMode(value: boolean) {
    this.editMode = value
  }
  public setCustomerFormValue(
    name: types.CustomerFormFieldsKeys,
    value: string
  ) {
    this.fields[name] = value
    if (!this.isFormUpdated) {
      this.isFormUpdated = true
    }
    this.validation.errors[name as keyof ErrorMessage] = ''
  }
  public setCustomer(value: Customer | null) {
    this.customer = value
    this.validation.errors.customer = ''
  }
  public async setGroups(
    authToken: string,
    params?: FetchAllGroupsParams
  ): Promise<void> {
    if (!authToken) return
    const response = await fetchAllGroups(authToken, params)
    this.groups = response
  }
  public setGroup(value: Group | null) {
    this.customerGroup = value
  }

  public addGroup(group: Group): string {
    this.groups.push(group)
    return group.id
  }

  public validateForm() {
    this.validation = validateForm({
      selectionMode: this.selectionMode,
      customer: this.customer,
      fields: this.fields,
    })
  }
  public validateEmbeddedForm() {
    this.validation = validateEmbeddedForm({
      customer: this.customer,
      fields: this.fields,
    })
  }
  public reset() {
    this.selectionMode = 'select_existing_customer'
    this.editMode = false
    this.isFormUpdated = false
    this.customer = null
    this.fields = customerFormFieldsInitialValues
    this.validation = {
      valid: true,
      errors: {},
    }
    this.groups = []
  }
}

export default CustomerForm
