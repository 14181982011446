import { CustomPalette, styled, Theme } from '@mui/system'

interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const FilterSelectorStyled = styled('div')((props: Props) => {
  const palette = props.theme?.palette
  return {
    '&': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'no-wrap',
      padding: '3px 4px',
      background: palette?.black?.plus100,
      border: `1px solid ${palette?.black?.plus70 ?? 'black'}`,
      boxSizing: 'border-box',
      borderRadius: '6px',
      a: {
        borderRadius: '4px',
        color: palette?.black?.plus10,
        background: palette?.black?.plus100,
        fontFamily: 'inherit',
        padding: '9px 16px',
        textDecoration: 'none',
        border: '0',
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0.04em',
        transition: 'background .2s ease, color .2s ease',
        '&:hover': {
          background: palette?.black?.plus85,
        },
        '&.active': {
          cursor: 'default',
          color: palette?.black?.plus100,
          background: palette?.black?.plus10,
        },
        [props.theme?.breakpoints.down('tablet') as string]: {
          fontSize: '12px',
        },
      },
    },
  }
})

export default FilterSelectorStyled
