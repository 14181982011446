import { CustomPalette, styled, Theme } from '@mui/system'
interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const SearchInputOptionStyled = styled('li')((props: Props) => {
  const palette = props.theme?.palette
  return {
    display: 'block',
    padding: '13px 16px',
    margin: '4px 0',
    backgroundColor: palette?.black?.plus100,
    color: palette?.black?.plus10,
    '.secondary': {
      marginLeft: '6px',
      color: palette?.black?.plus40,
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '100%',
    },
  }
})

export default SearchInputOptionStyled
