import { usePlan } from '@busie/core'
import { Plan, User } from '@busie/utils'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import {
  AppFeaturesType,
  DEFAULT_FEATURE_FLAGS,
} from '../../../config/featureFlags'

interface UseAuthAndFeaturesProps {
  user?: User
}

interface UseAuthAndFeaturesReturn {
  flags: AppFeaturesType
  plan?: Plan
}

/**
 * Hook to handle authentication and feature flags
 *
 * @param {UseAuthAndFeaturesProps} props - The authenticated user object
 * @returns {UseAuthAndFeaturesReturn} Object containing feature flags and subscription plan
 */

export const useAuthAndFeatures = ({
  user,
}: UseAuthAndFeaturesProps): UseAuthAndFeaturesReturn => {
  const ldClient = useLDClient()
  const launchDarklyFlags = useFlags<AppFeaturesType>()

  const { data: plan } = usePlan(user?.org_id, ['data'])

  // Merge LaunchDarkly flags with default flags
  const flags = {
    ...DEFAULT_FEATURE_FLAGS,
    ...launchDarklyFlags,
  }

  useEffect(() => {
    if (user) {
      void ldClient?.identify({
        kind: 'multi',
        user: {
          key: user.sub,
          name: user.name,
          email: user.email,
          organizationId: user.org_id,
          roles: user['https://getbusie.com/roles'].join(';'),
        },
        organization: {
          key: user['https://getbusie.com/organization'].id,
          name: user['https://getbusie.com/organization'].display_name,
          size: user['https://getbusie.com/organization'].metadata.size,
          orgType: user['https://getbusie.com/organization'].metadata.org_type,
        },
      })
    }
  }, [ldClient, user])

  return { flags, plan }
}
