import FormLabel from '@mui/material/FormLabel'
import { CustomPalette, Theme, styled } from '@mui/system'

interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const FormLabelStyled = styled(FormLabel)(({ theme }: Props) => {
  const palette = theme?.palette
  return {
    '&.MuiFormLabel-root': {
      lineHeight: '100%',
      color: palette?.black?.main,
      marginBottom: '16px',
      display: 'block',
    },
  }
})

export default FormLabelStyled
