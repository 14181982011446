import { observer } from 'mobx-react'
import { FC } from 'react'

import { QuoteFormProps } from './QuoteForm'
import { QuoteFormContent } from './QuoteFormContent'

export const QuoteFormEmbedded: FC<QuoteFormProps> = observer((props) => {
  return (
    <QuoteFormContent
      guestAuthToken={props.guestAuthToken}
      embedded={true}
      embeddedFormOrgId={props.embeddedFormOrgId}
      stepList={props.stepList}
      quoteId={props.quoteId}
      locale={props.locale}
      useMetricSystem={props.useMetricSystem}
      displayedAmenities={props.displayedAmenities}
    />
  )
})
