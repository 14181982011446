import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import store from '../../store'

import CustomerInformationBlock from './CustomerInformationBlock'
import DeparturesBlock from './DeparturesBlock'
import MapRouteBlock from './MapRouteBlock'
import OnSubmitMessageBlock from './OnSubmitMessageBlock'
import RouteInformationBlock from './RouteInformationBlock'
import TripInformationBlock from './TripInformationBlock'

import { useAmplitude } from '@busie/core'
import { useNavigate } from 'react-router-dom-v5-compat'
import Button from '../Button'
import { TripCreatedBlock } from './TripCreatedBlock'
interface Props {
  setCurrentStep: (step: number) => void
  setNextStep: (step: number) => void
  quotesAuthToken: string
  rateServiceAuthToken: string
  guestAuthToken?: string
  embedded?: boolean
  embeddedFormOrgId?: string
  onClose?: () => void
}

const SummaryBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const { track } = useAmplitude()
    const [showOnSubmitMessage, setShowOnSubmitMessage] =
      React.useState<boolean>(false)

    useEffect(() => {
      const legs = store.departuresForm.trip?._legs || []
      if (!((props.rateServiceAuthToken || props.guestAuthToken) && legs))
        return

      const updateLegPriceMap = async () => {
        for (const leg of legs) {
          await store.departuresForm.updateLegPriceMap(
            leg,
            {
              rateService: props.rateServiceAuthToken,
              guest: props.guestAuthToken,
            },
            props.embedded
          )
        }
      }

      void updateLegPriceMap()

      return () => {
        store.departuresForm.clearLegPriceMap()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rateServiceAuthToken, props.guestAuthToken])

    // stub navigation in the embedded mode for a hotfix to resolve Embedded Trip Planner crash
    // due to useNavigate being used outside of the context of a Router
    const navigate = props.embedded ? () => undefined : useNavigate()

    const submitInappForm = () => {
      store.resetAll()
      navigate('/')
    }

    const submitEmbeddedForm = () => setShowOnSubmitMessage(true)

    const handlerOnSubmit = () =>
      props.embedded ? submitEmbeddedForm() : submitInappForm()

    if (showOnSubmitMessage) {
      return <OnSubmitMessageBlock />
    }

    return (
      <>
        <TripCreatedBlock
          price={store.quote?._totalPrice ?? 0}
          onClose={props.onClose}
          showPrice={
            !props.embedded || !store.departuresForm.trip?.hideInstantQuote
          }
          onClickDone={handlerOnSubmit}
          embedded={props.embedded}
        />

        <CustomerInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4)
            props.setCurrentStep(0)
            store.customerForm.setEditMode(true)
            track('trip planning customer detail edit navigation')
          }}
          customerForm={store.customerForm}
        />

        <TripInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4)
            props.setCurrentStep(1)
            store.tripForm.setEditMode(true)
            track('trip planning trip edit navigation')
          }}
          tripForm={store.tripForm}
        />

        <RouteInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4)
            props.setCurrentStep(2)
            track('trip planning route edit navigation')
          }}
          departuresForm={store.departuresForm}
          isEmbedded={props.embedded}
          useMetricSystem={store.useMetricSystem}
          price={
            store.quote?._totalPrice ?? store.departuresForm.estimatedPrice
          }
        />

        <MapRouteBlock {...props} departuresForm={store.departuresForm} />

        <DeparturesBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4)
            props.setCurrentStep(3)
            track('trip planning departures edit navigation')
          }}
          departuresForm={store.departuresForm}
        />

        {!store.quoteId && (
          <Button fullWidth async onClick={handlerOnSubmit}>
            {props.embedded ? 'Next' : 'Done'}
          </Button>
        )}
      </>
    )
  }
)

export default SummaryBlock
