import { CustomPalette, styled } from '@mui/system'

import { Theme } from '@mui/system'

interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const HeaderStyled = styled('div')((props: Props) => {
  const palette = props.theme?.palette
  return {
    '&': {
      width: '100%',
      color: palette?.black?.plus100,
      background: palette?.black?.plus10,
      display: 'flex',
      position: 'sticky',
      top: '0',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '22px 24px',
      boxSizing: 'border-box',
      zIndex: 9,
      // boxShadow: `inset 0px 1px 0px ${palette?.black?.plus70}, inset 0px -1px 0px ${palette?.black?.plus70}`,
      '.title': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '150%',
        letterSpacing: '0.04em',
      },
      '.controls': {
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
  }
})

export default HeaderStyled
