import { Audiences, useAuth } from '@busie/core'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../components/PageHeader'
import { QuoteFormProps } from './QuoteForm'
import { QuoteFormContent } from './QuoteFormContent'
import store from './store'

export const QuoteFormWithAuth: FC<QuoteFormProps> = observer((props) => {
  const { getTokens } = useAuth()
  const nav = store.pageNavigation
  nav.stepList = props.stepList
  let customersToken = ''
  let pathfinderToken = ''
  let tripsToken = ''
  let quotesToken = ''
  let ratesToken = ''

  try {
    const tokens = getTokens([
      Audiences.CUSTOMERS,
      Audiences.PATHFINDER,
      Audiences.TRIPS,
      Audiences.QUOTES_AND_BOOKINGS,
      Audiences.RATES,
    ])
    customersToken = tokens.customers || ''
    pathfinderToken = tokens.pathfinder || ''
    tripsToken = tokens.trips || ''
    quotesToken = tokens.quotesAndBookings || ''
    ratesToken = tokens.rates || ''
  } catch (error) {
    console.error('Error fetching tokens with useAuth:', error)
  }

  const history = useHistory()

  const handleNavBack = () => {
    if (nav.currentStep === 0) {
      history.goBack()
    } else {
      nav.goPrev()
    }
  }

  return (
    <>
      <PageHeader title="Add quote" onNavBack={handleNavBack} />
      <QuoteFormContent
        customersAuthToken={customersToken}
        pathfinderAuthToken={pathfinderToken}
        tripsAuthToken={tripsToken}
        quotesAuthToken={quotesToken}
        rateServiceAuthToken={ratesToken}
        stepList={props.stepList}
        quoteId={props.quoteId}
        locale={props.locale}
        useMetricSystem={props.useMetricSystem}
        displayedAmenities={props.displayedAmenities}
      />
    </>
  )
})
