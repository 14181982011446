import { styled } from '@mui/system'

// import { Theme } from "@mui/system";

// interface Props {
//   theme?: Theme;
// }

const PageHeaderStyled = styled('div')(({ theme }) => {
  // const palette = props.theme?.palette;
  return {
    marginTop: '40px',
    marginBottom: '18px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '.page-title': {
      textAlign: 'center',
      margin: '0',
    },
    [`${theme?.breakpoints.down('tablet')}`]: {
      '.page-title': {
        display: 'none',
      },
      '.controls button': {
        border: 'none',
        background: 'transparent',
        paddingLeft: 0,
      },
    },
  }
})

export default PageHeaderStyled
