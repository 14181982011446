import React from 'react'
import {
  ActionMeta,
  GroupBase,
  Options,
  OptionsOrGroups,
  SingleValue,
} from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import InputLabel from '../InputLabel'
import { selectStyles } from './style-config'
import { StyledDiv } from './styled'

export interface SelectAsyncOption {
  label: string
  value: string
}

export interface SelectAsyncProps {
  loadOptions?: (
    inputValue: string,
    callback: (options: SelectAsyncOption[]) => void
  ) => void | Promise<
    OptionsOrGroups<SelectAsyncOption, GroupBase<SelectAsyncOption>>
  >
  value?: SingleValue<SelectAsyncOption>
  onChange: (
    newValue: SingleValue<SelectAsyncOption>,
    actionMeta: ActionMeta<SelectAsyncOption>
  ) => void
  onCreateOption?: (inputValue: string) => void | Promise<void>
  label?: string
  required?: boolean
  placeholder?: string
  isLoading?: boolean
  className?: string
  fullWidth?: boolean
  initialOptions?: SelectAsyncOption[]
  isValidNewOption?: (
    inputValue: string,
    selectValue: Options<SelectAsyncOption>
  ) => boolean
  onBlur?: () => void
  onInputChange?: (value: string) => void
}

export const SelectAsync: React.FC<SelectAsyncProps> = ({
  className,
  fullWidth = false,
  label,
  loadOptions,
  onChange,
  onCreateOption,
  placeholder = 'Search...',
  required = false,
  value,
  isLoading = false,
  isValidNewOption,
  initialOptions,
  onBlur,
  onInputChange,
}) => {
  return (
    <StyledDiv className={className} fullWidth={fullWidth} label={!!label}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <AsyncCreatableSelect<
        SelectAsyncOption,
        false,
        GroupBase<SelectAsyncOption>
      >
        isClearable
        isLoading={isLoading}
        onChange={onChange}
        onCreateOption={onCreateOption}
        value={value}
        placeholder={placeholder}
        styles={selectStyles}
        loadOptions={loadOptions}
        isValidNewOption={isValidNewOption}
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? `No existing groups named "${inputValue}"`
            : 'Type to search'
        }
        createOptionPosition="first"
        allowCreateWhileLoading={false}
        defaultOptions={initialOptions || true}
        onBlur={onBlur}
        onInputChange={onInputChange}
      />
    </StyledDiv>
  )
}
