import { DefaultPrivacyLevel } from '@datadog/browser-rum'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Switch } from 'react-router-dom'

import {
  DatadogProvider,
  initAmplitude,
  queryClient,
  useAuth,
  UserContextProvider,
} from '@busie/core'
import { Loading, theme } from '@busie/ui-kit'
import { env } from '@busie/utils'
import { InternalPages } from '../../pages'

/**
 * Main application wrapper component that sets up core functionality and providers
 *
 * Provides:
 * - Authentication check and user context
 * - Analytics (Amplitude and Datadog)
 * - Theme provider
 * - React Query for data fetching
 * - Routing
 *
 * Features:
 * - Redirects unauthenticated users
 * - Initializes analytics with user data
 * - Configures Datadog RUM (Real User Monitoring)
 * - Sets up application routing structure
 *
 * @component
 * @example
 * ```tsx
 * <ApplicationCore>
 *   <App />
 * </ApplicationCore>
 */
export const ApplicationCore: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApplicationCoreInner>{children}</ApplicationCoreInner>
    </QueryClientProvider>
  )
}

const ApplicationCoreInner: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, isBatchLoading, isAuthenticated, isLoading, logout } = useAuth()

  React.useEffect(() => {
    if (user) {
      initAmplitude({ apiKey: env('AMPLITUDE_API_KEY'), user })
    }
  }, [user])

  if (!user) return <h3>You must be logged in to access this application</h3>

  return (
    <DatadogProvider
      user={user}
      enabled={env('ENABLE_RUM') === 'true'}
      sessionReplayRecording={env('ENABLE_RUM') === 'true'}
      applicationId={env('RUM_APPLICATION_ID')}
      clientToken={env('RUM_CLIENT_TOKEN')}
      site={env('RUM_SITE')}
      service={env('RUM_SERVICE')}
      env={env('RUM_ENV')}
      version={env('RUM_VERSION')}
      sessionSampleRate={parseInt(env('RUM_SAMPLE_RATE') || '100')}
      sessionReplaySampleRate={parseInt(env('RUM_SAMPLE_RATE') || '100')}
      trackUserInteractions={env('RUM_TRACK_INTERACTIONS') === 'true'}
      trackResources={env('RUM_TRACK_RESOURCES') === 'true'}
      trackLongTasks={env('RUM_TRACK_LONG_TASKS') === 'true'}
      trackFrustrations={env('RUM_TRACK_FRUSTRATIONS') === 'true'}
      allowedTracingUrls={Array.from(
        new Set(
          [
            env('BUSIE_RATE_SERVICE_API_URL'),
            env('BUSIE_CUSTOMERS_SERVICE_API_URL'),
            env('BUSIE_PATHFINDER_SERVICE_API_URL'),
            env('BUSIE_VEHICLE_SERVICE_API_URL'),
            env('BUSIE_QUOTES_AND_BOOKINGS_SERVICE_API_URL'),
            env('BUSIE_TRIPS_SERVICE_API_URL'),
            env('BUSIE_PAYMENT_SERVICE_API_URL'),
            env('BUSIE_ETP_BFF_SERVICE_API_URL'),
            env('BUSIE_DISPATCH_LOCATION_SERVICE_API_URL'),
            env('BUSIE_PROFILE_SERVICE_API_URL'),
            env('BUSIE_PLANS_SERVICE_API_URL'),
          ].filter((url) => url.length && url.startsWith('https://'))
        )
      )}
      defaultPrivacyLevel={
        (env('RUM_DEFAULT_PRIVACY_LEVEL') || 'allow') as DefaultPrivacyLevel
      }
      enableHeatmap={env('RUM_ENABLE_HEATMAP') === 'true'}
      enableFeatureFlags={env('FEATURE_FLAGS_ENABLED') === 'true'}
    >
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <div className="App">
            {isBatchLoading ? (
              <Loading
                fillContainer
                withLayout
                authProps={{
                  user,
                  isAuthenticated,
                  logout,
                  isLoading,
                }}
              />
            ) : (
              <Switch>
                <InternalPages />
              </Switch>
            )}
          </div>
        </UserContextProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </DatadogProvider>
  )
}
