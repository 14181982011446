import { styled } from '@mui/system'
import { DateRange } from 'react-date-range'

/**
 * Styled wrapper for ReactDateRangePicker component
 */
export const ReactDateRangePickerStyled = styled(DateRange)(() => {
  return {
    width: '100%',
    '& .rdrMonth': {
      width: '100%',
    },
  }
})
