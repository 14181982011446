import { useMediaQuery } from '@mui/material'
import { CustomPalette, styled } from '@mui/system'

import { Theme } from '@mui/system'

interface Props {
  fullwidth?: boolean
  bordered?: boolean
  theme: Theme & { palette: CustomPalette }
}

export const FormContainerStyled = styled('div')<Props>((props: Props) => {
  const palette = props.theme.palette
  const isMobile = useMediaQuery('@media (max-width: 650px)')

  return {
    background: palette?.black?.plus100,
    boxShadow: props.bordered
      ? `inset 0px -1px 0px ${palette.black.plus70}, inset -1px 0px 0px ${palette.black.plus70}, inset 0px 1px 0px ${palette?.black?.plus70}, inset 1px 0px 0px ${palette?.black?.plus70}`
      : undefined,
    borderRadius: isMobile ? 0 : '8px',
    maxWidth: '740px',
    minWidth: isMobile ? '375px' : '500px',
    width: 'inherit',
    height: isMobile ? '100%' : 'auto',
    padding: props.bordered ? '45px 40px 40px' : undefined,
    [props.theme.breakpoints.down('tablet')]: {
      padding: '15px 25px',
    },
    margin: isMobile ? 0 : '16px auto 0',
    boxSizing: 'border-box',
    '&.fullWidth': {
      maxWidth: '100%',
      width: '100%',
    },
    '.title-block': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: props.bordered ? '30px' : '15px',
    },
    '& .form-submit-control': {
      paddingTop: '40px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '16px',
    },
  }
})
