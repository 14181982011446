import { CustomPalette, styled, Theme } from '@mui/system'

interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const AmenityBlockStyled = styled('div')((props: Props) => {
  const palette = props.theme?.palette
  return {
    display: 'inline-block',
    width: 'auto',
    margin: '0',
    textAlign: 'center',
    button: {
      background: palette?.black?.plus85,
      border: `1px solid ${palette?.black?.plus80 ?? 'black'}`,
      boxSizing: 'border-box',
      borderRadius: '4px',
      height: '80px',
      width: '80px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all .2s ease',
      '&:hover, &:focus': {
        background: palette?.black?.plus80,
        borderColor: palette?.black?.plus70,
        outline: 'none',
      },
      '&.active': {
        background: palette?.red?.plus45,
        borderColor: palette?.red?.main,
      },
    },
    label: {
      display: 'block',
      color: palette?.black?.main,
      marginTop: '8px',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '150%',
    },
  }
})

export default AmenityBlockStyled
