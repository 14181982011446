import { Stepper } from '@busie/ui-kit'
import { observer } from 'mobx-react'
import { FC } from 'react'

import { BusieLocale } from '@busie/utils'
import ProviderNameLabel from './components/ProviderNameLabel'
import FormBlock from './FormBlock'
import { getIcon } from './getIcon'
import { QuoteFormProps } from './QuoteForm'
import store from './store'
import QuoteFormStyled from './styled/QuoteFormStyled'

export const QuoteFormContent: FC<QuoteFormProps> = observer(
  ({
    customersAuthToken,
    pathfinderAuthToken,
    tripsAuthToken,
    quotesAuthToken,
    rateServiceAuthToken,
    guestAuthToken,
    embedded,
    embeddedFormOrgId,
    stepList,
    quoteId,
    locale,
    useMetricSystem,
    displayedAmenities,
  }) => {
    store.locale = locale || BusieLocale.EN_US
    store.useMetricSystem = useMetricSystem || false
    store.displayedAmenities = displayedAmenities || []
    const nav = store.pageNavigation
    nav.stepList = stepList

    return (
      <QuoteFormStyled embedded={embedded}>
        <Stepper step={nav.currentStep} getIcon={getIcon} stepList={stepList} />
        <FormBlock
          customersAuthToken={customersAuthToken || ''}
          pathfinderAuthToken={pathfinderAuthToken || ''}
          tripsAuthToken={tripsAuthToken || ''}
          quotesAuthToken={quotesAuthToken || ''}
          rateServiceAuthToken={rateServiceAuthToken || ''}
          guestAuthToken={guestAuthToken || ''}
          embedded={embedded}
          embeddedFormOrgId={embeddedFormOrgId}
          step={nav.currentStep}
          goNext={() => nav.goNext()}
          goPrev={() => nav.goPrev()}
          setCurrentStep={(index) => nav.setCurrentStep(index)}
          setNextStep={(index) => nav.setNextStep(index)}
          stepList={stepList}
          quoteId={quoteId}
        />
        {embedded && <ProviderNameLabel />}
      </QuoteFormStyled>
    )
  }
)
