import { DateRange, normalizeRangeSelection, useLocale } from '@busie/utils'

import React from 'react'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import { type Range } from 'react-date-range'

import { FlexContainer } from '../FlexContainer'
import InputLabel from '../InputLabel'
import palette from '../theme/palette'

import { ReactDateRangePickerStyled } from './styled/ReactDateRangePicker'

/**
 * Props for the DateRangePicker component
 * @interface Props
 * @property {string | JSX.Element} [label] - Optional label text or element to display above the date picker
 * @property {boolean} [required] - Whether the date range selection is required
 * @property {number} [maxWidth] - Maximum width of the date picker container
 * @property {DateRange} value - Currently selected date range
 * @property {boolean} [disablePast] - Whether to disable selection of past dates
 * @property {(newValue: DateRange) => void} onChange - Callback fired when date range selection changes
 */
interface Props {
  /** Optional label text or element to display above the date picker */
  label?: string | JSX.Element
  /** Whether the date range selection is required */
  required?: boolean
  /** Maximum width of the date picker container */
  maxWidth?: number
  /** Currently selected date range */
  value: DateRange
  /** Whether to disable selection of past dates */
  disablePast?: boolean
  /** Callback fired when date range selection changes */
  onChange: (newValue: DateRange) => void
}

/**
 * A date range picker component that allows selecting a start and end date
 * @param {Props} props - Component props
 * @returns {JSX.Element} DateRangePicker component
 */
export const DateRangePicker: React.FC<Props> = ({
  value,
  onChange,
  label,
  required,
  disablePast,
}: Props): JSX.Element => {
  const handleOnChange = ({ selection }: { selection: Range }) => {
    const dateRange = normalizeRangeSelection(selection)
    onChange(dateRange)
  }

  const selectionRange: Range = {
    startDate: value[0]?.toDate() || new Date(),
    endDate: value[1]?.toDate() || new Date(),
    key: 'selection',
  }

  const { locale } = useLocale()

  return (
    <FlexContainer direction="column" w="100%">
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <ReactDateRangePickerStyled
        ranges={[selectionRange]}
        onChange={(selection: Range) => handleOnChange({ selection })}
        moveRangeOnFirstSelection
        rangeColors={[palette?.red?.plus10]}
        minDate={disablePast ? new Date() : undefined}
        locale={locale}
      />
    </FlexContainer>
  )
}
