import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@busie/ui-kit'

export const LoginButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Button typestyle="secondary" onClick={loginWithRedirect}>
      Login
    </Button>
  )
}
