import CircularProgress from '@mui/material/CircularProgress'
import { FC, PropsWithChildren } from 'react'
import { Layout, LayoutProps } from '.'

import LoadingStyled from './styled/LoadingStyled'

export interface LoadingBlockProps {
  padding?: string
  size?: number | string

  width?: number | string
  height?: number | string

  display?: 'flex' | 'block'
  direction?: 'column' | 'row'

  justifyContent?: 'center' | 'flex-start' | 'flex-end'
  alignItems?: 'center' | 'flex-start' | 'flex-end'

  fillContainer?: boolean
  withLayout?: boolean

  authProps?: LayoutProps['authProps']
}

const fillContainerProps: LoadingBlockProps = {
  display: 'flex',
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}

const LoadingBlock: FC<PropsWithChildren<LoadingBlockProps>> = (props) => {
  const { fillContainer, withLayout, authProps, ...rest } = props
  const loadingProps: LoadingBlockProps = fillContainer
    ? fillContainerProps
    : {}

  const Loading = withLayout ? (
    <Layout authProps={authProps}>
      <LoadingStyled {...rest} {...loadingProps}>
        <CircularProgress size={props.size} color="primary" />
      </LoadingStyled>
    </Layout>
  ) : (
    <LoadingStyled {...rest} {...loadingProps}>
      <CircularProgress size={props.size} color="primary" />
    </LoadingStyled>
  )
  return Loading
}

export default LoadingBlock
