import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker'

import { TextInput } from '../TextInput'
import { CloseIcon, CalendarIcon } from '../icons'
import palette from '../theme/palette'
import { Button } from '../Button'

import { getInputTextValue } from './helpers'
import DateRangePickerControlledStyled from './styled/DateRangePickerControlledStyled'
import { useOnClickOutside } from '../DatePicker/helpers'
import { Box } from '@mui/system'
import { DateRange } from '@busie/utils'

interface Props {
  label?: string | JSX.Element
  maxWidth?: number
  value: DateRange
  disablePast: boolean
  onSubmit: (newValue: DateRange) => void
  placeholder?: string
}
export const DateRangePickerControlled: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const [value, setValue] = React.useState<DateRange>(props.value)
  const [inputValue, setInputValue] = React.useState<string>(
    getInputTextValue(props.value)
  )
  const [isCalendarShown, setIsCalendarShown] = React.useState<boolean>(false)

  const handleOnSubmit = (newValue: DateRange) => {
    props.onSubmit(newValue)
    setInputValue(getInputTextValue(newValue))
  }

  const showCalendar = () => {
    setIsCalendarShown(true)
  }

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>

  useOnClickOutside(ref, () => setIsCalendarShown(false))

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePickerControlledStyled>
        <>
          <TextInput
            onFocus={showCalendar}
            placeholder={props.placeholder || 'mm/dd/yyyy'}
            value={inputValue}
            readOnly={true}
            endAdornment={
              !inputValue ? (
                <CalendarIcon color={palette.black.plus30} />
              ) : (
                <span
                  tabIndex={0}
                  role="button"
                  className="close-button"
                  onClick={() => handleOnSubmit([null, null])}
                >
                  <CloseIcon color={palette.black.plus30} />
                </span>
              )
            }
          />

          {isCalendarShown && (
            <div className="calendar-toolbox" ref={ref}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                // renderInput={(startProps, endProps) => <span />}
              />
              <Box sx={{ padding: '10px 15px', textAlign: 'center' }}>
                <Button onClick={() => handleOnSubmit(value)}>
                  Set filter by date range
                </Button>
              </Box>
            </div>
          )}
        </>
      </DateRangePickerControlledStyled>
    </LocalizationProvider>
  )
}
