import React from 'react'

import { Button, ChevronPrev, H1 } from '@busie/ui-kit'

import { palette } from '@busie/ui-kit'

import PageHeaderStyled from '../components/styled/PageHeaderStyled'

interface Props {
  title: string
  onNavBack?: () => void
}

export const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  onNavBack,
}: Props) => {
  return (
    <PageHeaderStyled>
      <div className="controls">
        {onNavBack && (
          <Button
            typestyle="tertiary"
            onClick={onNavBack}
            startIcon={<ChevronPrev color={palette?.black?.plus10} />}
            sx={{
              border: '2px solid',
              borderColor: palette?.black?.plus80,
              borderRadius: '4px',
            }}
          >
            Back
          </Button>
        )}
      </div>
      <H1 className="page-title">{title}</H1>
    </PageHeaderStyled>
  )
}
