import React from 'react'
import Autocomplete from 'react-google-autocomplete'

import { env, GooglePlace } from '@busie/utils'

import PlaceFieldBlockStyled from './PlaceFieldBlockStyled'
import { createHtmlAttrId } from '../helpers'
import InputLabel from '../InputLabel'
import IconButton from '../IconButton'
import { DeleteIcon } from '../icons'

interface Props {
  id?: string
  label?: string
  placeholder?: string
  fieldValue?: string
  onSelect: (location: GooglePlace) => void
  onRemove?: () => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  startAdornment?: React.ReactNode
  margin?: string | number
  width?: string
  disabled?: boolean
}

const PlaceField: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const inputRef = React.useRef(null)
  const apiKey = env('BUSIE_GOOGLE_MAPS_API_KEY')
  const id =
    props.id ||
    `place-field-${createHtmlAttrId(props.label || props.placeholder)}`

  return (
    <PlaceFieldBlockStyled margin={props.margin} width={props.width}>
      {props.label && <InputLabel htmlFor={id}>{props.label}</InputLabel>}
      <div className="field-block" style={{ width: props.width }}>
        <div className="input-block">
          {props.startAdornment && (
            <div className="start-adornment">{props.startAdornment}</div>
          )}
          <Autocomplete<{ disabled: boolean }>
            id={id}
            ref={inputRef}
            apiKey={apiKey}
            onPlaceSelected={(selected) => {
              props.onSelect(selected as GooglePlace)
            }}
            onChange={props.onChange ? props.onChange : undefined}
            disabled={props.disabled ?? false}
            placeholder={props.placeholder}
            options={{
              types: [],
              fields: [
                'address_components',
                'geometry.location',
                'place_id',
                'formatted_address',
              ],
              componentRestrictions: { country: ['us', 'ca', 'mx'] },
            }}
            defaultValue={props.fieldValue}
            style={{
              paddingLeft: props.startAdornment ? '27px' : '',
            }}
          />
        </div>
        {props.onRemove && (
          <IconButton
            onClick={props.onRemove}
            type="button"
            icon={<DeleteIcon />}
            aria-label={`delete ${props.label ?? 'location'}`}
          />
        )}
      </div>
    </PlaceFieldBlockStyled>
  )
}

export default PlaceField
