import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel'

import { CustomPalette, Theme, styled } from '@mui/system'
interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const InputLabelStyled = styled(InputLabel)((props: Props) => {
  const palette = props.theme?.palette
  return {
    [`&.${inputLabelClasses.root}`]: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '150%',
      letterSpacing: '0.04em',
      color: palette?.black?.plus30,
      marginBottom: '8px',
      '.red': {
        color: palette?.red?.main,
      },
    },
  }
})

export default InputLabelStyled
