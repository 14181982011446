import { User } from '@busie/utils'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import theme from '../theme'
import { Header, HeaderItem } from './Header'
import Container from './styled/Container'
import LayoutStyled from './styled/LayoutStyled'

export interface LayoutProps {
  children: React.ReactNode
  isCustomer?: boolean
  headerLinks?: HeaderItem[]
  authProps?: {
    user?: User
    isAuthenticated?: boolean
    logout?: ({ returnTo }: { returnTo: string }) => void
    isLoading?: boolean
  }
}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  isCustomer,
  headerLinks,
  authProps,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutStyled>
        <Header
          isCustomer={isCustomer}
          headerLinks={headerLinks}
          authProps={authProps}
        />
        <Container>{children}</Container>
      </LayoutStyled>
    </ThemeProvider>
  )
}
