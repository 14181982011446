import { CustomPalette, styled, Theme } from '@mui/system'

interface Props {
  theme?: Theme & { palette: CustomPalette }
  size?: 'large' | 'short'
  justify:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
    | 'center'
  margin?: string
}

const PropertyStringStyled = styled('div')((props: Props) => {
  const palette = props.theme?.palette
  return {
    '&': {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '20px',
      justifyContent: props.justify,
      fontSize: '14px',
      fontWeight: '600',
      margin: props.margin || '0 0 5px 0',
      minHeight: '32px',
      alignItems: 'center',
      '.name': {
        maxWidth: props.size === 'large' ? '390px' : '265px',
        width: '100%',
        color: palette?.black?.plus30,
      },
      '.value': {
        color: palette?.black?.main,
        a: {
          color: palette?.red?.main,
          textDecoration: 'none',
          '&:hover,&:focus': {
            color: palette?.red?.minus5,
          },
        },
      },
    },
  }
})

export default PropertyStringStyled
