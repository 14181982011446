import React from 'react'
import { EmbeddedTripPlannerSettings, getOrganizationAsGuest } from '@busie/api'

export const useEmbeddedOrganization = (
  authToken: string,
  organizationApiKey: string
): { organizationId: string; settings: EmbeddedTripPlannerSettings | null } => {
  const [orgId, setOrgId] = React.useState('')
  const [settings, setSettings] =
    React.useState<EmbeddedTripPlannerSettings | null>(null)
  React.useEffect(() => {
    if (!authToken) return
    const getOrganizationId = async (): Promise<void> => {
      try {
        const organization = await getOrganizationAsGuest(
          authToken,
          organizationApiKey
        )

        setOrgId(organization._id)
        setSettings(organization)
      } catch (e) {
        console.error(e)
      }
    }
    void getOrganizationId()
  }, [authToken, organizationApiKey])

  return { organizationId: orgId, settings }
}
