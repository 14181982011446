import { CustomPalette, Theme } from '@mui/system'

import Button, { ButtonProps, buttonClasses } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import { getColorsOfType, getPaddingValues } from './helpers'

interface Props extends ButtonProps {
  typestyle?: 'primary' | 'secondary' | 'tertiary' | 'accent'
  typesize: 'big' | 'small' | 'mobile'
  isLoading?: boolean
  fullWidth?: boolean
  theme?: Theme & { palette: CustomPalette }
}

const ButtonStyled = styled(Button)((props: Props) => {
  const colors = getColorsOfType(props.theme?.palette, props.typestyle)
  const padding = getPaddingValues(props.typesize, props.startIcon as boolean)
  const isMobile = props.typesize === 'mobile'
  return {
    [`&.${buttonClasses.root}`]: {
      fontWeight: isMobile ? '400' : '600',
      lineHeight: '100%',
      paddingTop: padding.top,
      paddingRight: padding.right,
      paddingBottom: padding.bottom,
      paddingLeft: padding.left,
      color: colors.default.color,
      background: colors.default.background,
      textTransform: 'none',
      border: `2px solid ${colors.default.borderColor ?? 'black'}`,
      boxSizing: 'border-box',
      boxShadow: 'none',
      borderRadius: '4px',
      width: props.fullWidth ? '100%' : 'auto',
      '.button-content': {
        visibility: 'visible',
      },
      '&.loading': {
        position: 'relative',
        '.button-content': {
          visibility: 'hidden',
        },
      },
      '.loading-content': {
        position: 'absolute',
        left: '0',
        right: '0',
      },
      '&:hover, &:focus': {
        color: colors.hover.color,
        background: colors.hover.background,
        borderColor: colors.hover.borderColor,
      },
      '&:active': {
        color: colors.pressed.color,
        background: colors.pressed.background,
        borderColor: colors.pressed.borderColor,
      },
      ...props.sx,
    },

    [`&.${buttonClasses.disabled}`]: {
      color: colors.disabled.color,
      background: colors.disabled.background,
      borderColor: colors.disabled.borderColor,
    },

    [`.${buttonClasses.startIcon}`]: {
      marginRight: '6px',
      marginLeft: '0',
      svg: {
        width: '24px',
        height: '24px',
      },
    },
  }
})

export default ButtonStyled
