import { useMemo, type FC } from 'react'

import { NotificationContainer } from '@busie/features'
import { Layout } from '@busie/ui-kit'

import { useAuth } from '@busie/core'
import { Routes } from '../../shared/routing/Routes'
import { isFeatureEnabled } from '../../utils/featureFlags'
import { routes } from './config'
import { useAuthAndFeatures } from './hooks/useAuthAndFeatures'
import { useHeaderLinks } from './hooks/useHeaderLinks'
import { useOnboarding } from './hooks/useOnboarding'

export const InternalPages: FC = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth()
  const { flags, plan } = useAuthAndFeatures({ user })

  const enabledRoutes = useMemo(() => {
    return routes.filter((route) => isFeatureEnabled(flags, route.featureFlag))
  }, [flags])

  useOnboarding(user)

  const { headerLinks } = useHeaderLinks({
    routes: enabledRoutes,
    flags,
    plan,
  })

  return (
    <Layout
      authProps={{
        user,
        isAuthenticated,
        logout,
        isLoading,
      }}
      headerLinks={headerLinks}
    >
      <Routes routes={enabledRoutes} />
      <NotificationContainer />
    </Layout>
  )
}
