import { env } from '@busie/utils'

/**
 * Default Auth0 configuration for token requests
 */
export const defaultAuthTokenConf = {
  domain: env('BUSIE_AUTH_DOMAIN'),
  clientId: env('BUSIE_CLIENT_ID'),
  redirect_uri: window.location.origin,
}

/**
 * Mapping of audience keys to their corresponding URLs
 */
export const audienceUrls = {
  customers: 'https://customers.getbusie.com',
  dispatchLocations: 'https://dispatch-locations.getbusie.com',
  payments: 'https://payments.getbusie.com',
  plans: 'https://plans.getbusie.com',
  profile: 'https://profile.getbusie.com',
  quotesAndBookings: 'https://quotes-and-bookings.getbusie.com',
  rates: 'https://rates.getbusie.com',
  vehicles: 'https://vehicles.getbusie.com',
  tripPlanner: 'https://trip-planner.getbusie.com',
  pathfinder: 'https://pathfinder.getbusie.com',
  trips: 'https://trips.getbusie.com',
  drivers: 'https://drivers.getbusie.com',
  chef: 'https://chef.getbusie.com',
  embeds: 'https://ea-embed-retriever.getbusie.com',
} as const

/**
 * Type definition for valid audience keys
 */
export type Audience = keyof typeof audienceUrls

/**
 * Enum of valid audience keys
 */
export enum Audiences {
  CUSTOMERS = 'customers',
  DISPATCH_LOCATIONS = 'dispatchLocations',
  PAYMENTS = 'payments',
  PATHFINDER = 'pathfinder',
  PLANS = 'plans',
  PROFILE = 'profile',
  QUOTES_AND_BOOKINGS = 'quotesAndBookings',
  RATES = 'rates',
  VEHICLES = 'vehicles',
  TRIPS = 'trips',
  DRIVERS = 'drivers',
  CHEF = 'chef',
  EMBEDS = 'embeds',
}
/**
 * Timeout duration for pending token requests (in milliseconds)
 */
export const PENDING_REQUEST_TIMEOUT = 10000 // 10 seconds

/**
 * Window duration for batching token requests (in milliseconds)
 */
export const BATCH_WINDOW = 25 // 25ms for batching window

/**
 * Predefined groups of commonly requested audiences.
 * Optimized based on actual usage patterns and dependencies.
 */
export const COMMON_AUDIENCE_GROUPS: Audience[][] = [
  // Core user data group - typically needed first
  [Audiences.PROFILE, Audiences.CUSTOMERS, Audiences.PLANS],

  [Audiences.QUOTES_AND_BOOKINGS, Audiences.CHEF],
]

/**
 * Configuration for token expiration and caching
 */
export const TOKEN_CONFIG = {
  /** Safety buffer before token expiration (in milliseconds) */
  EXPIRATION_BUFFER_MS: 10 * 60 * 1000, // 10 minutes
  /** Default token lifetime if expires_in claim is missing (in milliseconds) */
  DEFAULT_TOKEN_LIFETIME_MS: 60 * 60 * 1000, // 1 hour
  /** Maximum allowed token lifetime (in milliseconds) */
  MAX_TOKEN_LIFETIME_MS: 24 * 60 * 60 * 1000, // 24 hours
} as const
