export { default as BlackCarLimousineIcon } from './blackCarLimousine'
export { default as BlackCarSuvIcon } from './blackCarSuv'
export { default as DoubleDeckerIcon } from './doubleDecker'
export { default as EntertainerCoachIcon } from './entertainerCoach'
export { default as ExecutiveCoachIcon } from './executiveCoach'
export { default as MiniBusIcon } from './miniBus'
export { default as MotorcoachIcon } from './motorcoach'
export { default as SchoolBusIcon } from './schoolBus'
export { default as SleeperBusIcon } from './sleeperBus'
export { default as TrolleyIcon } from './trolley'
export { default as VanIcon } from './van'

export interface VehicleIconProps {
  color?: string
  size?: number
}
