/**
 * @fileoverview AuthTokensProvider manages authentication tokens for multiple audiences in a React application.
 * Implements advanced token management features including batching, caching, and performance monitoring.
 *
 * Key features:
 * - Token request batching to reduce Auth0 API calls
 * - Intelligent caching with expiration
 * - Performance monitoring and metrics
 * - Configurable logging system
 * - Error handling and retry logic
 *
 * Note: Token expiration is implemented but not currently covered by automated tests.
 * See auth-token-expiration.md for implementation details.
 *
 * @example
 * ```tsx
 * import { AuthTokensProvider } from '@busie/core'
 *
 * const App = () => (
 *   <AuthTokensProvider>
 *     <YourApp />
 *   </AuthTokensProvider>
 * )
 * ```
 */

import { useAuth0 } from '@auth0/auth0-react'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  audienceUrls,
  BATCH_WINDOW,
  COMMON_AUDIENCE_GROUPS,
  defaultAuthTokenConf,
  PENDING_REQUEST_TIMEOUT,
  TOKEN_CONFIG,
  type Audience,
} from './constants'

/**
 * Configuration interface for the logging system.
 * Controls logging behavior across different environments.
 */
interface LoggingConfig {
  enabled: boolean
  metrics: boolean
  level: 'debug' | 'info' | 'warn' | 'error'
  environments: string[]
}

/**
 * Types for logging system messages and arguments.
 */
type LogMessage =
  | string
  | number
  | boolean
  | null
  | undefined
  | Error
  | unknown[]
  | Record<string, unknown>
  | unknown

type LogArgs = LogMessage[]

/**
 * Default logging configuration.
 * Enables logging only in development environment.
 */
const DEFAULT_LOGGING_CONFIG: LoggingConfig = {
  enabled: process.env.NODE_ENV === 'development',
  metrics: false,
  level: 'debug',
  environments: ['development'],
}

let loggingConfig = { ...DEFAULT_LOGGING_CONFIG }

// Logging override utilities
export const configureTokenLogging = (config: Partial<LoggingConfig>) => {
  loggingConfig = { ...loggingConfig, ...config }
}

export const resetTokenLogging = () => {
  loggingConfig = { ...DEFAULT_LOGGING_CONFIG }
}

const shouldLog = () => {
  return (
    loggingConfig.enabled ||
    loggingConfig.metrics ||
    loggingConfig.environments.includes(process.env.NODE_ENV || 'development')
  )
}

const log = {
  debug: (...args: LogArgs) => {
    if (shouldLog() && loggingConfig.level === 'debug') {
      console.log('🚌', ...args)
    }
  },
  info: (...args: LogArgs) => {
    if (shouldLog() && ['debug', 'info'].includes(loggingConfig.level)) {
      console.log('🚌', ...args)
    }
  },
  warn: (...args: LogArgs) => {
    if (
      shouldLog() &&
      ['debug', 'info', 'warn'].includes(loggingConfig.level)
    ) {
      console.warn('🚌', ...args)
    }
  },
  error: (...args: LogArgs) => {
    if (shouldLog()) {
      console.error('🚌', ...args)
    }
  },
  metric: (...args: LogArgs) => {
    if (loggingConfig.metrics) {
      console.log('🚌 [Metric]', ...args)
    }
  },
}

/**
 * Interface for the auth tokens context value.
 * Defines the shape of the context provided to consumers.
 */
interface AuthTokensContextValue {
  getToken: (audience: Audience) => string
  isBatchLoading: boolean
}

/**
 * Interface for performance metrics tracking.
 * Used to monitor system health and optimization effectiveness.
 */
interface PerformanceMetrics {
  cacheHitRate: number
  averageFetchDuration: number
  batchingEfficiency: number
  tokenCount: number
  errorRate: number
  lastRefreshTimestamp: number
}

/**
 * Interface for individual token fetch metrics.
 * Tracks timing and success rate of token operations.
 */
interface TokenFetchMetric {
  timestamp: number
  duration: number
  success: boolean
  audiences: string[]
}

const AuthTokensContext = createContext<AuthTokensContextValue | undefined>(
  undefined
)

/**
 * Interface for the token cache entries.
 * Each entry contains the token and its expiration timestamp.
 */
interface TokenCache {
  [audience: string]: {
    token: string
    expiresAt: number
    /** Raw token expiration time from Auth0 in seconds */
    expiresIn: number | null
  }
}

interface TokenClaims {
  exp: number
  [key: string]: unknown
}

/**
 * Interface for tracking pending token requests.
 * Used to prevent duplicate requests and enable batching.
 */
interface PendingRequest {
  promise: Promise<string>
  timestamp: number
}

/**
 * Global performance metrics state.
 * Tracks various metrics for monitoring and optimization.
 */
const performanceMetrics = {
  tokenFetches: [] as TokenFetchMetric[],
  cacheHits: 0,
  cacheMisses: 0,
  errors: 0,
  totalRequests: 0,
}

export const getPerformanceSummary = (): PerformanceMetrics => {
  const now = Date.now()
  const recentFetches = performanceMetrics.tokenFetches.filter(
    (fetch) => now - fetch.timestamp < 3600000 // Last hour
  )

  const totalFetches = recentFetches.length
  const successfulFetches = recentFetches.filter(
    (fetch) => fetch.success
  ).length
  const totalDuration = recentFetches.reduce(
    (sum, fetch) => sum + fetch.duration,
    0
  )
  const batchedTokens = recentFetches.reduce(
    (sum, fetch) => sum + fetch.audiences.length,
    0
  )

  return {
    cacheHitRate:
      performanceMetrics.totalRequests > 0
        ? performanceMetrics.cacheHits / performanceMetrics.totalRequests
        : 0,
    averageFetchDuration: totalFetches > 0 ? totalDuration / totalFetches : 0,
    batchingEfficiency: totalFetches > 0 ? batchedTokens / totalFetches : 0,
    tokenCount: performanceMetrics.tokenFetches.length,
    errorRate:
      performanceMetrics.totalRequests > 0
        ? performanceMetrics.errors / performanceMetrics.totalRequests
        : 0,
    lastRefreshTimestamp:
      recentFetches.length > 0
        ? Math.max(...recentFetches.map((f) => f.timestamp))
        : 0,
  }
}

/**
 * Calculates token expiration timestamp based on expires_in claim
 * @param expiresIn Token expiration time in seconds from Auth0
 * @returns Timestamp when token should be considered expired
 */
const calculateTokenExpiration = (expiresIn: number | null): number => {
  const now = Date.now()
  const tokenLifetimeMs = expiresIn
    ? Math.min(expiresIn * 1000, TOKEN_CONFIG.MAX_TOKEN_LIFETIME_MS)
    : TOKEN_CONFIG.DEFAULT_TOKEN_LIFETIME_MS

  return now + tokenLifetimeMs - TOKEN_CONFIG.EXPIRATION_BUFFER_MS
}

/**
 * Main provider component for auth token management.
 * Handles token fetching, caching, and performance monitoring.
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components that will have access to auth tokens
 *
 * @example
 * ```tsx
 * <AuthTokensProvider>
 *   <AuthenticatedComponent />
 * </AuthTokensProvider>
 * ```
 */
export const AuthTokensProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0()
  const [tokenCache, setTokenCache] = useState<TokenCache>({})
  const pendingRequests = useRef<{ [audience: string]: PendingRequest }>({})
  const batchTimeout = useRef<NodeJS.Timeout | null>(null)
  const pendingBatch = useRef<Set<Audience>>(new Set())
  const eagerLoadingComplete = useRef<boolean>(false)
  const [batchLoading, setBatchLoading] = useState<boolean>(false)

  const timerLabels = useRef<Set<string>>(new Set())

  const cleanupPendingRequests = useCallback(() => {
    const now = Date.now()
    Object.entries(pendingRequests.current).forEach(([audience, request]) => {
      if (now - request.timestamp > PENDING_REQUEST_TIMEOUT) {
        delete pendingRequests.current[audience]
      }
    })
  }, [])

  const startTimer = useCallback((label: string) => {
    if (timerLabels.current.has(label)) {
      log.warn(`[Timer] Already exists: ${label}`)
      return false
    }
    timerLabels.current.add(label)
    if (shouldLog()) {
      console.time(label)
    }
    return true
  }, [])

  const endTimer = useCallback((label: string) => {
    if (!timerLabels.current.has(label)) {
      log.warn(`[Timer] Does not exist: ${label}`)
      return
    }
    if (shouldLog()) {
      console.timeEnd(label)
    }
    timerLabels.current.delete(label)
  }, [])

  const fetchToken = useCallback(
    async (audience: Audience): Promise<string> => {
      const startTime = Date.now()
      try {
        const token = await getAccessTokenSilently({
          ...defaultAuthTokenConf,
          audience: audienceUrls[audience],
        })

        // Extract expires_in from token claims
        let expiresIn: number | null = null
        try {
          const claims = JSON.parse(atob(token.split('.')[1])) as TokenClaims
          expiresIn = claims.exp
            ? claims.exp - Math.floor(Date.now() / 1000)
            : null
        } catch (e) {
          log.warn('Failed to parse token claims:', e)
        }

        const expiresAt = calculateTokenExpiration(expiresIn)

        setTokenCache((prev) => ({
          ...prev,
          [audience]: {
            token,
            expiresAt,
            expiresIn,
          },
        }))

        // Log metrics
        const duration = Date.now() - startTime
        performanceMetrics.tokenFetches.push({
          timestamp: startTime,
          duration,
          success: true,
          audiences: [audience],
        })

        return token
      } catch (error) {
        performanceMetrics.errors++
        log.error('Error fetching token:', error)
        throw error
      }
    },
    [getAccessTokenSilently]
  )

  const fetchTokens = useCallback(
    async (audiences: Audience[]): Promise<Record<Audience, string>> => {
      const timerLabel = `batchTokenFetch-${audiences.join(',')}`
      const shouldTime = startTimer(timerLabel)
      const startTime = Date.now()

      log.info('[Token Batch] Starting batch fetch for audiences:', audiences)

      const results: Record<Audience, string> = {} as Record<Audience, string>
      const timestamp = Date.now()
      let success = true

      try {
        const tokens = await Promise.all(
          audiences.map(async (audience) => {
            try {
              const token = await fetchToken(audience)
              return { audience, token }
            } catch (e) {
              if ((e as { message: string }).message === 'Consent required') {
                const token = await getAccessTokenWithPopup({
                  ...defaultAuthTokenConf,
                  audience: audienceUrls[audience],
                })
                return { audience, token }
              }
              log.error(
                `[Token Batch] Error fetching token for ${audience}:`,
                e
              )
              performanceMetrics.errors++
              success = false
              return { audience, token: '' }
            }
          })
        )

        const newCache: TokenCache = {}
        tokens.forEach(({ audience, token }) => {
          if (token) {
            results[audience] = token
            newCache[audience] = {
              token,
              expiresAt: timestamp + 50 * 60 * 1000,
              expiresIn: null,
            }
          }
        })

        setTokenCache((prev) => ({ ...prev, ...newCache }))

        if (loggingConfig.metrics) {
          log.metric('Token Fetch', {
            audiences,
            duration: Date.now() - timestamp,
            success: Object.keys(results).length === audiences.length,
          })
        }
      } catch (e) {
        log.error('[Token Batch] Batch fetch error:', e)
        performanceMetrics.errors++
        success = false
      } finally {
        audiences.forEach((audience) => {
          delete pendingRequests.current[audience]
        })
      }

      const duration = Date.now() - startTime
      performanceMetrics.tokenFetches.push({
        timestamp: startTime,
        duration,
        success,
        audiences,
      })

      if (shouldTime) {
        endTimer(timerLabel)
      }
      return results
    },
    [fetchToken, getAccessTokenWithPopup, startTimer, endTimer]
  )

  const processBatch = useCallback(() => {
    const audiences = Array.from(pendingBatch.current)
    if (audiences.length === 0) return

    pendingBatch.current.clear()
    batchTimeout.current = null

    log.debug('[Token Batch] Processing batch:', audiences)

    const batchPromise = fetchTokens(audiences)

    audiences.forEach((audience) => {
      pendingRequests.current[audience] = {
        promise: batchPromise.then((results) => results[audience] || ''),
        timestamp: Date.now(),
      }
    })
  }, [fetchTokens])

  const scheduleBatch = useCallback(
    (audience: Audience) => {
      pendingBatch.current.add(audience)

      if (batchTimeout.current) {
        clearTimeout(batchTimeout.current)
      }

      batchTimeout.current = setTimeout(processBatch, BATCH_WINDOW)
    },
    [processBatch]
  )

  const getToken = useCallback(
    (audience: Audience): string => {
      performanceMetrics.totalRequests++
      const cached = tokenCache[audience]
      const now = Date.now()

      if (cached && cached.expiresAt > now) {
        performanceMetrics.cacheHits++
        if (shouldLog()) {
          log.debug(`[Token Cache] Hit for audience: ${audience}`)
        }
        return cached.token
      }

      performanceMetrics.cacheMisses++
      if (shouldLog()) {
        log.debug(`[Token Cache] Miss for audience: ${audience}`)
      }
      scheduleBatch(audience)
      cleanupPendingRequests()
      return cached?.token || ''
    },
    [tokenCache, scheduleBatch, cleanupPendingRequests]
  )

  // Set up periodic cleanup
  useEffect(() => {
    if (!isAuthenticated) return

    const cleanupInterval = setInterval(
      cleanupPendingRequests,
      PENDING_REQUEST_TIMEOUT / 2
    )

    return () => {
      clearInterval(cleanupInterval)
    }
  }, [isAuthenticated, cleanupPendingRequests])

  // Eager loading of common token combinations
  useEffect(() => {
    if (!isAuthenticated || eagerLoadingComplete.current) return

    const eagerLoadTokens = async () => {
      log.info('Starting eager load of common tokens')
      setBatchLoading(true)
      eagerLoadingComplete.current = true

      // Load groups sequentially to maintain order priority
      for (const group of COMMON_AUDIENCE_GROUPS) {
        const needsFetch = group.some(
          (audience) =>
            !tokenCache[audience] ||
            tokenCache[audience].expiresAt <= Date.now()
        )
        if (needsFetch) {
          log.info('Eager loading group:', group)
          await fetchTokens(group)
        }
      }

      log.info('Eager loading complete')
      setBatchLoading(false)
    }

    void eagerLoadTokens()
  }, [isAuthenticated, fetchTokens, tokenCache])

  // Clear token cache when auth state changes
  useEffect(() => {
    if (!isAuthenticated) {
      log.info('Clearing cache due to auth state change')
      setTokenCache({})
      pendingRequests.current = {}
      pendingBatch.current.clear()
      eagerLoadingComplete.current = false
      if (batchTimeout.current) {
        clearTimeout(batchTimeout.current)
        batchTimeout.current = null
      }
    }
  }, [isAuthenticated])

  // Update cache logging to use new system
  useEffect(() => {
    log.info(
      '[Token Cache] Current cache size:',
      Object.keys(tokenCache).length
    )

    if (loggingConfig.metrics) {
      log.metric('Cache State', {
        size: Object.keys(tokenCache).length,
        timestamp: Date.now(),
      })
    }
  }, [tokenCache])

  const value = React.useMemo(
    () => ({
      getToken,
      isBatchLoading: batchLoading,
    }),
    [getToken, batchLoading]
  )

  return (
    <AuthTokensContext.Provider value={value}>
      {children}
    </AuthTokensContext.Provider>
  )
}

/**
 * Hook for accessing the auth tokens context.
 * Must be used within an AuthTokensProvider component.
 *
 * @throws {Error} When used outside of AuthTokensProvider
 * @returns {AuthTokensContextValue} Context containing getToken function
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   const { getToken } = useAuthTokensContext()
 *   const token = getToken('profile')
 *   return <div>Authenticated Component</div>
 * }
 * ```
 */
export const useAuthTokensContext = () => {
  const context = useContext(AuthTokensContext)
  if (!context) {
    throw new Error(
      'useAuthTokensContext must be used within an AuthTokensProvider'
    )
  }
  return context
}
