import { buttonClasses } from '@mui/material/Button'
import { CustomPalette, styled, Theme } from '@mui/system'

interface Props {
  embedded?: boolean
  theme?: Theme & { palette: CustomPalette }
}

const QuoteFormStyled = styled('div')<Props>(({ embedded, theme }) => {
  const palette = theme?.palette
  return {
    padding: embedded ? '30px 15px' : '0',
    width: '100%',
    backgroundColor: palette?.black?.plus85,
    [theme.breakpoints.down('tablet')]: {
      padding: '0 0 30px 0',
      [`.${buttonClasses.root}`]: {
        width: '100%',
        maxWidth: 'none !important',
      },
    },
  }
})

export default QuoteFormStyled
