import useMediaQuery from '@mui/material/useMediaQuery'
import React, { Fragment } from 'react'
import { useLocation } from 'react-router'

import { getBrandingLogo, isOrganizationSettingsAllowed } from '@busie/utils'
import Link from './HeaderLink'
import Logo from './Logo'

import { LayoutProps } from '.'
import { FlexContainer } from '../FlexContainer'
import MobileNavigationMenu from './MobileNavigationMenu'
import { NavigationMenu } from './NavigationMenu'
import ProfileMenu from './ProfileMenu'
import AppBarStyled from './styled/AppBarStyled'
import ToolbarStyled from './styled/ToolbarStyled'

export interface HeaderItem {
  name: string
  to: string
  children?: { name: string; path: string; order?: number }[]
}

interface Props {
  isCustomer?: boolean
  headerLinks?: HeaderItem[]
  authProps?: LayoutProps['authProps']
}

export const Header: React.FC<React.PropsWithChildren<Props>> = ({
  isCustomer,
  headerLinks = [],
  authProps,
}) => {
  const { user, isAuthenticated, logout, isLoading } = authProps || {}
  const location = useLocation()
  const isDesktopSized = useMediaQuery('(min-width:1116px)')
  const isSettingsAllowed = user && isOrganizationSettingsAllowed(user)
  const brandingLogo = getBrandingLogo(user)
  const currentPage = headerLinks.find((hl) =>
    hl.children?.length
      ? hl.children.some((c) => location.pathname.includes(c.path))
      : hl.to === location.pathname.slice(0, hl.to.length)
  )
  const currentPageName = currentPage?.name.replace(' & ', '&')

  if (isCustomer) {
    return null
  }

  const LoginContainer =
    !isAuthenticated && isLoading ? (
      <Link to={'/login'} exact={true}>
        Login
      </Link>
    ) : null

  const ProfileMenuContainer = () => {
    if (isLoading) {
      if (!isAuthenticated) {
        return LoginContainer
      }
    }
    return (
      <FlexContainer align="center">
        <ProfileMenu
          logout={() =>
            logout?.({ returnTo: window.location.origin + '/login' })
          }
          user={user}
          isSettingsAllowed={!!isSettingsAllowed}
        />
      </FlexContainer>
    )
  }

  const isActive = (path: string) => {
    return location.pathname.includes(path.split('*')[0])
  }

  return (
    <AppBarStyled position="static">
      <ToolbarStyled>
        {!isCustomer && isDesktopSized ? (
          <Fragment>
            <FlexContainer align="center">
              <Logo logoSrc={brandingLogo} />

              {headerLinks.map((item, i) => {
                return item.children && item.children.length ? (
                  <NavigationMenu
                    key={item.name}
                    items={item.children.map(({ path, name }) => ({
                      name,
                      to: path,
                      active: isActive(path),
                    }))}
                    name={item.name}
                    active={item.children.some(({ path }) => isActive(path))}
                  />
                ) : (
                  <Link
                    to={item.to}
                    exact={true}
                    className={
                      location.pathname.slice(0, item.to.length) === item.to
                        ? 'selected'
                        : ''
                    }
                    key={item.to}
                  >
                    {item.name}
                  </Link>
                )
              })}
            </FlexContainer>
            <ProfileMenuContainer />
          </Fragment>
        ) : (
          <Fragment>
            <Logo />
            <span>{currentPageName}</span>
            <MobileNavigationMenu
              headerLinks={headerLinks}
              logout={() =>
                logout?.({
                  returnTo: window.location.origin + '/login',
                })
              }
            />
          </Fragment>
        )}
      </ToolbarStyled>
    </AppBarStyled>
  )
}
