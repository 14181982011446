import { QueryKey, UseQueryResult, useQuery } from 'react-query'

import { Member, Profile, fetchUsers } from '@busie/api'

import { queryClient } from './client'
import { useAuthTokenWithAudience } from '../lib/hooks'

export const QUERY_KEY: QueryKey = 'USERS'

export const invalidate = () => {
  void queryClient.invalidateQueries(QUERY_KEY)
}

export interface UsersMap {
  [id: string]: Member
}

export const useUsers = (): UseQueryResult<UsersMap, Error> => {
  const token = useAuthTokenWithAudience('profile')

  return useQuery<Profile, Error, UsersMap>(
    [QUERY_KEY],
    () => fetchUsers(token),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!token,
      select: (data) =>
        data.members?.reduce((prev: UsersMap, current) => {
          prev[current.user_id] = current
          return prev
        }, {}),
    }
  )
}
