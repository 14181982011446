import React from 'react'

import RadioGroup from '@mui/material/RadioGroup'
import { Theme, SxProps } from '@mui/material/styles'
import Radio, { RadioProps } from '@mui/material/Radio'
import RadioButtonStyled from './styled/RadioButtonStyled'

import { createHtmlAttrId } from '../helpers'

export type RadioButtonChangeEvent = React.ChangeEvent<HTMLInputElement>

export const RadioButtonGroup = RadioGroup

interface RadioButtonProps {
  label: string
  value: string | number
  radioProps?: Partial<RadioProps>
  sx?: SxProps<Theme>
}

export const RadioButton: React.FC<
  React.PropsWithChildren<RadioButtonProps>
> = (props: RadioButtonProps) => {
  return (
    <RadioButtonStyled
      sx={props.sx}
      id={`checkbox-${createHtmlAttrId(props.label)}`}
      value={props.value}
      control={<Radio {...props.radioProps} />}
      label={props.label}
    />
  )
}
