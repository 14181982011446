import { Location } from '@busie/utils'

interface RawLocation {
  _id: string
  _city: string
  _latitude: number
  _longitude: number
  _address: string
  _street: string
  _state: string
  _country: string
  _postalCode: string
  _createdAt: string
  _createdBy: string
}

export const formatLocation = (
  rawLocation: RawLocation | Location
): Location => {
  if ((rawLocation as Location).id) {
    return rawLocation as Location
  }

  const {
    _id: id,
    _city: city,
    _latitude: latitude,
    _longitude: longitude,
    _address: address,
    _street: street,
    _state: state,
    _country: country,
    _postalCode: postalCode,
    _createdAt: createdAt,
    _createdBy: createdBy,
  } = rawLocation as RawLocation

  return {
    id,
    city,
    latitude,
    longitude,
    address,
    street,
    state,
    country,
    postalCode,
    createdAt,
    createdBy,
  } as Location
}
