import { CustomPalette, Theme, styled } from '@mui/system'

export interface Props {
  theme?: Theme & { palette: CustomPalette }
  type?: string
  disabled?: boolean
  highlightOnHover?: boolean
  size?: number
}

const IconButtonStyled = styled('button', {
  shouldForwardProp: (prop) => prop !== 'highlightOnHover',
})<Props>((props) => {
  const palette = props.theme?.palette
  return {
    border: '0',
    background: 'none',
    width: `${props.size || 40}px`,
    height: `${props.size || 40}px`,
    borderRadius: '2px',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    transition: 'background 0.2s ease',
    opacity: props.disabled ? '.3' : '1',
    '&:hover,&:focus': {
      background: props.highlightOnHover ? palette?.black?.plus80 : 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export default IconButtonStyled
