import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const ReportRoad: React.FC<React.PropsWithChildren<Props>> = ({
  size = '16',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 14.0001V2.62012"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6668 9.34675V2.66675"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 9.3333C3.3335 9.3333 3.91683 8.84863 5.66683 8.84863C7.41683 8.84863 8.5835 9.99997 10.3335 9.99997C12.0835 9.99997 12.6668 9.34863 12.6668 9.34863"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 2.62133C3.3335 2.62133 3.91683 2 5.66683 2C7.41683 2 8.5835 3.15133 10.3335 3.15133C12.0835 3.15133 12.6668 2.66667 12.6668 2.66667"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
