import React from 'react'

import { TableProps } from '@mui/material/Table'
import { CustomPalette, styled, Theme } from '@mui/system'
import { FlexContainer } from '../FlexContainer'
import { Typography } from '@mui/material'
import { tableColumns, tableData } from './types'

interface Props extends TableProps {
  // required props to render table
  columnNames: tableColumns
  data: tableData
  emptyState?: React.ReactNode

  //optional props for select row functionality
  withCheckbox?: boolean
  checkboxKeys?: string[]
  rowTypes?: string[]
  selectedRows?: string[]
  onRowClick?: (rowSelector: string, type?: string) => void
  onRowsSelect?: (rows: string[]) => void
  onRowsRemove?: (rows: string[]) => void
}

const ItemContainer = styled('div')(
  ({ theme }: { theme?: Theme & { palette: CustomPalette } }) => ({
    background: 'white',
    width: '100%',
    border: `1px solid ${theme?.palette.black.plus70 ?? 'black'}`,
    borderRadius: '6px',
    padding: '24px 18px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    cursor: 'pointer',
    justifyContent: 'space-evenly',
    columnGap: '16px',
  })
)

const Title = styled(Typography)(
  ({ theme }: { theme?: Theme & { palette: CustomPalette } }) => ({
    color: theme?.palette.black.plus30,
    fontSize: '10px',
  })
)

const Value = styled(Typography)(
  ({ theme }: { theme?: Theme & { palette: CustomPalette } }) => ({
    color: theme?.palette.black.plus10,
    fontSize: '14px',
    paddingTop: '4px',
    fontWeight: 600,
  })
)

export const Table: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <FlexContainer direction="column" rowGap={1.5} sx={{ margin: '13px 0px' }}>
      {props.data.length
        ? props.data.map((row, rowIndex) => {
            return (
              <ItemContainer
                onClick={() =>
                  props.onRowClick &&
                  props.onRowClick(
                    (props.checkboxKeys && props.checkboxKeys[rowIndex]) || '',
                    (props.rowTypes && props.rowTypes[rowIndex]) || ''
                  )
                }
              >
                {Object.keys(row).map((key, index) => (
                  <FlexContainer
                    direction="column"
                    sx={{
                      flexBasis: 'calc(50% - 16px)',
                      flexGrow: 1,
                      minWidth: 0,
                    }}
                  >
                    <Title>{props.columnNames[index].name}</Title>
                    <Value>{row[key]}</Value>
                  </FlexContainer>
                ))}
              </ItemContainer>
            )
          })
        : props.emptyState}
    </FlexContainer>
  )
}
