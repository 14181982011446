import { createTheme, ThemeOptions } from '@mui/material'

import palette from './palette'

const options: ThemeOptions = {
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    allVariants: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.04em',
    },
  },
  palette,
  breakpoints: {
    unit: 'px',
    values: {
      mobile: 0,
      tablet: 769,
      laptop: 1024,
      desktop: 1216,
      widescreen: 1408,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.85,
          },
        },
      },
    },
  },
}

declare module '@mui/system' {
  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true
    tablet: true
    laptop: true
    desktop: true
    widescreen: true
    up: (key: BreakpointOverrides | number) => string
    down: (key: BreakpointOverrides | number) => string
    between: (
      start: BreakpointOverrides | number,
      end: BreakpointOverrides | number
    ) => string
    only: (key: BreakpointOverrides) => string
  }

  interface CustomPalette {
    primary: {
      main: string
    }
    black: {
      main: string
      plus10: string
      plus20: string
      plus30: string
      plus40: string
      plus50: string
      plus60: string
      plus70: string
      plus80: string
      plus85: string
      plus90: string
      plus100: string
    }
    red: {
      minus5: string
      main: string
      plus5: string
      plus10: string
      plus40: string
      plus45: string
      plus80: string
    }
    supernova: {
      minus10: string
      main: string
      plus10: string
      plus40: string
    }
    green: {
      minus5: string
      main: string
      plus5: string
      plus55: string
      lineRun: string
    }
    blue: {
      minus10: string
      main: string
      plus10: string
      plus40: string
    }
    purple: {
      minus10: string
      main: string
      plus10: string
      plus40: string
    }
    success: {
      main: string
    }
    warning: {
      main: string
    }
    error: {
      main: string
    }
  }
}

const theme = createTheme(options)

export default theme
