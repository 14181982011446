import { MUIStyledCommonProps, styled, SxProps, Theme } from '@mui/system'
import React, { forwardRef } from 'react'
import palette from '../theme/palette'

export interface FlexContainerProps {
  children?: React.ReactNode
  className?: string
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
    | 'stretch'
    | 'center'
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'justify-all'
    | 'match-parent'
  columnGap?: number
  rowGap?: number
  bordered?: boolean
  fullWidth?: boolean
  noPadding?: boolean
  px?: number
  py?: number
  p?: number
  pl?: number
  pt?: number
  pr?: number
  pb?: number
  borderBetweenX?: boolean
  borderBetweenY?: boolean
  w?: number | string
  h?: number | string
  grow?: boolean
  wrap?: string
  sx?: SxProps<Theme>
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  minW?: number | string
  maxW?: number | string
  index?: number
}

interface StyleParams {
  [key: string]: string | number | StyleParams
}

type Props = MUIStyledCommonProps<Theme> & FlexContainerProps

const StyledFlexContainer = styled('div', {
  shouldForwardProp: (prop) =>
    !(
      ['columnGap', 'minW', 'maxW', 'rowGap', 'fullWidth'] as PropertyKey[]
    ).includes(prop),
})((props: Props) => {
  let styles: StyleParams = {
    display: 'flex',
    flexDirection: props.direction || 'row',
    justifyContent: props.justify || 'flex-start',
    alignItems: props.align || 'flex-start',
    columnGap: props.theme?.spacing(props.columnGap || 0) || 0,
    rowGap: props.theme?.spacing(props.rowGap || 0) || 0,
    flexGrow: props.grow ? 1 : 0,
    flexWrap: props.wrap || 'auto',
    textAlign: props.textAlign || 'left',
    boxSizing: 'border-box',
  }

  if (props.fullWidth) {
    styles.width = '100%'
  }

  if (props.borderBetweenX) {
    styles['&>:not([hidden])~:not([hidden])'] = {
      borderRight: 'none',
      borderLeft: `1px solid ${palette?.black?.plus70}`,
    }
  }

  if (props.borderBetweenY) {
    styles['&>:not([hidden])~:not([hidden])'] = {
      borderTop: `1px solid ${palette?.black?.plus70}`,
      borderBottom: 'none',
    }
  }

  if (props.bordered) {
    styles = {
      ...styles,
      padding: '8px 24px',
      border: `1px solid ${palette?.black?.plus70}`,
      borderRadius: '4px',
      backgroundColor: '#fff',
    }

    if (props.noPadding) {
      styles.padding = 0
    }
  }

  if (props.w) {
    styles.width = typeof props.w === 'string' ? props.w : `${props.w}px`
  }

  if (props.minW) {
    styles.minWidth =
      typeof props.minW === 'string' ? props.minW : `${props.minW}px`
  }

  if (props.maxW) {
    styles.maxWidth =
      typeof props.maxW === 'string' ? props.maxW : `${props.maxW}px`
  }

  if (props.h) {
    styles.height = typeof props.h === 'string' ? props.h : `${props.h}px`
  }

  if (props.p != null) {
    styles.padding = props.theme?.spacing(props.p) || 0
  }
  if (props.px != null) {
    styles.paddingLeft = props.theme?.spacing(props.px) || 0
    styles.paddingRight = props.theme?.spacing(props.px) || 0
  }
  if (props.py != null) {
    styles.paddingTop = props.theme?.spacing(props.py) || 0
    styles.paddingBottom = props.theme?.spacing(props.py) || 0
  }
  if (props.pl != null) {
    styles.paddingLeft = props.theme?.spacing(props.pl) || 0
  }
  if (props.pt != null) {
    styles.paddingTop = props.theme?.spacing(props.pt) || 0
  }
  if (props.pr != null) {
    styles.paddingRight = props.theme?.spacing(props.pr) || 0
  }
  if (props.pb != null) {
    styles.paddingBottom = props.theme?.spacing(props.pb) || 0
  }

  return styles
})

export const FlexContainer = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<FlexContainerProps>
>((props, ref) => {
  return <StyledFlexContainer {...props} ref={ref} />
})
