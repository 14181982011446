import { CustomPalette, styled, Theme } from '@mui/system'
import TextField, { textFieldClasses } from '@mui/material/TextField'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

const SelectInputStyled = styled(TextField)(({
  theme,
}: {
  theme?: Theme & { palette: CustomPalette }
}) => {
  return {
    background: theme?.palette.black.plus85,
    [`&.${textFieldClasses.root}`]: {
      maxWidth: '420px',
      [`.${outlinedInputClasses.root}`]: {
        padding: '3px 15px 2px 50px',
      },
      // ".MuiAutocomplete-endAdornment": {
      //   display: "none",
      // },
    },
  }
})

export default SelectInputStyled
