import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const PrintRoad: React.FC<React.PropsWithChildren<Props>> = ({
  size = '16',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6665 5.33333V2.66667C4.6665 2.29867 4.96517 2 5.33317 2H10.6665C11.0345 2 11.3332 2.29867 11.3332 2.66667V5.33333"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66667 11.3333H3.33333C2.59667 11.3333 2 10.7366 2 9.99992V6.66659C2 5.92992 2.59667 5.33325 3.33333 5.33325H12.6667C13.4033 5.33325 14 5.92992 14 6.66659V9.99992C14 10.7366 13.4033 11.3333 12.6667 11.3333H11.3333"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6665 9.19995H11.3332V13.3333C11.3332 13.7013 11.0345 14 10.6665 14H5.33317C4.96517 14 4.6665 13.7013 4.6665 13.3333V9.19995Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 7.33333H5.33317"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
