import { QueryClient } from 'react-query'

export const TOKEN_CACHE_CONFIG = {
  staleTime: 5 * 60 * 1000, // 5 minutes
  cacheTime: 10 * 60 * 1000, // 10 minutes
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  retry: 2,
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...TOKEN_CACHE_CONFIG,
      // Other global query configurations
      suspense: false,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: false,
    },
  },
})
