import { styled, Theme, CustomPalette } from '@mui/system'
import DialogTitle from '@mui/material/DialogTitle'

interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const PopupTitleStyled = styled(DialogTitle)<Props>((props: Props) => {
  const palette = props.theme?.palette
  return {
    padding: '25px 25px 15px 25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.title': {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.04em',
      color: palette?.black?.plus10,
    },
    '.subtitle': {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '100%',
      letterSpacing: '0.04em',
      color: palette?.black?.plus10,
    },
  }
})

export default PopupTitleStyled
