import React from 'react'
import { triggerLocalVisitorIdHook, authenticateGuestUser } from '@busie/api'

export const useGuestAuthToken = (
  organizationApiKey: string,
  visitorId: string,
  organizationDomain: string
): string => {
  const [authToken, setAuthToken] = React.useState('')

  React.useEffect(() => {
    const getToken = async (): Promise<void> => {
      try {
        if (process.env.NODE_ENV === 'development') {
          await triggerLocalVisitorIdHook(
            organizationApiKey,
            `https://${organizationDomain}`,
            visitorId
          )
        }
        const token = await authenticateGuestUser(
          organizationApiKey,
          organizationDomain,
          visitorId
        )

        setAuthToken(token)
      } catch (e) {
        console.error(e)
      }
    }

    void getToken()
  }, [organizationApiKey, visitorId, organizationDomain])

  return authToken
}
