import React from 'react'

interface Props {
  position: 'origin' | 'middle' | 'destination'
}

export const ManeuverIcon: React.FC<React.PropsWithChildren<Props>> = ({
  position,
}) => {
  return {
    origin: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2426 3.75736C14.5858 6.10051 14.5858 9.89952 12.2426 12.2426C9.89949 14.5858 6.10051 14.5858 3.75736 12.2426C1.41421 9.89949 1.41421 6.10048 3.75736 3.75736C6.10051 1.41421 9.89949 1.41421 12.2426 3.75736"
          stroke="#A1A1A1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="8" cy="8" r="2" fill="#A1A1A1" />
      </svg>
    ),
    middle: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2426 3.75736C14.5858 6.10051 14.5858 9.89952 12.2426 12.2426C9.89949 14.5858 6.10051 14.5858 3.75736 12.2426C1.41421 9.89949 1.41421 6.10048 3.75736 3.75736C6.10051 1.41421 9.89949 1.41421 12.2426 3.75736"
          stroke="#A1A1A1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    destination: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 6.78071C13 4.14066 10.7614 2 8 2C5.2386 2 3 4.14066 3 6.78071C3 9.57122 6.02797 12.5685 7.37113 13.7611C7.73062 14.0796 8.26938 14.0796 8.62887 13.7611C9.97203 12.5685 13 9.57122 13 6.78071Z"
          stroke="#A1A1A1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.06066 5.43934C9.64645 6.02513 9.64645 6.97488 9.06066 7.56066C8.47487 8.14645 7.52513 8.14645 6.93934 7.56066C6.35355 6.97487 6.35355 6.02512 6.93934 5.43934C7.52513 4.85355 8.47487 4.85355 9.06066 5.43934Z"
          fill="#A1A1A1"
        />
      </svg>
    ),
  }[position]
}
