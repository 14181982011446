import React from 'react'
import { LoginButton } from './components/LoginButton'

export const AuthFlow: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <h1>Grow Your Charter Business</h1>
      <h3>
        Busie is a cloud-based charter sales platform designed to make life
        easier for charter operators.
      </h3>
      <LoginButton />
    </div>
  )
}
