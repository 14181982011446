import React from 'react'
import { CustomPalette, styled, Theme } from '@mui/system'
import defaultLogo from './logo.svg'

const LogoStyled = styled('div')(({
  theme,
}: {
  theme?: Theme & { palette: CustomPalette }
}) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '18px',
    color: 'black',
    gap: '10px',
    '& p': {
      margin: 0,
      fontSize: '10px',
      color: theme?.palette.black.plus30,
    },
  }
})

const Divider = styled('div')(({
  theme,
}: {
  theme?: Theme & { palette: CustomPalette }
}) => {
  return {
    height: '30px',
    width: '1px',
    backgroundColor: theme?.palette.black.plus60,
  }
})

interface LogoProps {
  logoSrc?: string
}

const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  logoSrc = defaultLogo,
}) => {
  return (
    <LogoStyled>
      <img src={logoSrc} loading="lazy" alt="Busie Logo" height="30px" />
      <Divider />
      <div>
        <p>Powered</p>
        <p>by Busie</p>
      </div>
    </LogoStyled>
  )
}

export default Logo
