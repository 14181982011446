import H from '@here/maps-api-for-javascript'
import palette from '../theme/palette'

import { MapMarkerCoordinates } from './map.types'

import PointIcon from './icons/point'

interface MapBounds {
  top: number
  left: number
  bottom: number
  right: number
}

export const getMapPadding = (fullPage = false): MapBounds => ({
  top: 100,
  left: fullPage ? 500 : 100,
  bottom: 100,
  right: 100,
})

export const getMapBounds = (coordinates: MapMarkerCoordinates): MapBounds => {
  const minLat = Math.min(...coordinates.lat)
  const maxLat = Math.max(...coordinates.lat)
  const minLng = Math.min(...coordinates.lng)
  const maxLng = Math.max(...coordinates.lng)
  return { top: minLat, left: minLng, bottom: maxLat, right: maxLng }
}

export const getMarkerIcons = (): {
  [key: string]: (index?: number) => H.map.Icon
} => {
  return {
    start: () =>
      new H.map.Icon(
        PointIcon({
          borderColor: palette?.black?.plus10,
          backgroundColor: palette?.black?.plus100,
          label: 'A',
        })
      ),
    end: () =>
      new H.map.Icon(
        PointIcon({
          borderColor: palette?.black?.plus100,
          backgroundColor: palette?.black?.plus10,
          label: 'B',
        })
      ),
    trip: (index?: number) =>
      new H.map.Icon(
        PointIcon({
          borderColor: palette?.black?.plus100,
          backgroundColor: palette?.red?.main,
          label: index ? index.toString() : '',
        })
      ),
  }
}
