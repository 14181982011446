import React from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Loading } from '@busie/ui-kit'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<React.PropsWithChildren<any>>
  loadingComponent?: React.ReactElement
  [key: string]: unknown
}

const ProtectedRoute = ({
  component,
  loadingComponent,
  ...args
}: Props): React.ReactElement => {
  const qs = window.location.search
  const urlParams = new URLSearchParams(qs)
  const invitation = urlParams.get('invitation') || undefined
  const organization = urlParams.get('organization') || undefined
  //TODO: It needs some changes to the quote/bookings logic to work properly
  // const protectedComponent = React.useMemo(
  //   () =>
  //     withAuthenticationRequired(component, {
  //       onRedirecting: () => loadingComponent || <Loading />,
  //       loginOptions: {
  //         invitation,
  //         organization,
  //       },
  //     }),
  //   [component, invitation, loadingComponent, organization]
  // );
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => loadingComponent || <Loading />,
        loginOptions: {
          invitation,
          organization,
        },
      })}
      {...args}
    />
  )
}

export default ProtectedRoute
