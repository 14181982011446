import CustomerForm from './components/CustomerForm'
import TripForm from './components/TripForm'
import RouteForm from './components/RouteForm'
import DeparturesForm from './components/DeparturesForm'
import SummaryBlock from './components/SummaryBlock'

const steps = {
  Customer: CustomerForm,
  Trip: TripForm,
  Route: RouteForm,
  Departures: DeparturesForm,
  Summary: SummaryBlock,
} as const

export type StepName = keyof typeof steps
