export type locale = 'en-us' | 'es-mx'

/**
 * Date formats for the useLocale hook.
 *
 * @see https://date-fns.org/v3.0.1/docs/format
 */
export enum DateFormats {
  /** Full day of the week, e.g., 'Monday' */
  DAY_OF_WEEK = 'EEEE',

  /** Short numeric date, e.g., '04/29/1453' */
  SHORT_DATE = 'P',

  /** Short time with AM/PM, e.g., '12:00 AM' */
  SHORT_TIME = 'p',

  /** Short numeric date with time, e.g., '04/29/1453 12:00 AM' */
  SHORT_DATE_TIME = 'P p',

  /** Short numeric date with time, comma separated (localized), e.g., '04/29/1453, 12:00 AM' */
  SHORT_DATE_TIME_COMMA = 'Pp',

  /** Short numeric date with time and day of the week, e.g., 'Monday, 04/29/1453 12:00 AM' */
  SHORT_DATE_TIME_WITH_DAY = 'EEEE, P p',

  /** Long localized date, e.g., 'April 29th, 1453' */
  LONG_DATE = 'PPP',

  /** Long localized date with time, e.g., 'April 29th, 1453 at 12:00 AM' */
  LONG_DATE_TIME = 'PPPp',

  /** Long localized date with time and day of the week, e.g., 'Monday, April 29th, 1453 at 12:00 AM' */
  LONG_DATE_TIME_WITH_DAY = 'EEEE, PPPp',

  /** Day of the week + short date with time, e.g., 'Monday, 01/31/2025 4:49 PM' */
  DAY_SHORT_DATE_TIME = 'EEEE, P p',
}

export interface UseLocaleProps {
  defaultLocale?: Locale
  domain?: string
}

export interface UseLocaleReturn {
  locale: Locale
  d: (date: string, format: string) => string
  t: (key: string) => string
  usesMetricSystem: boolean
  busieLocale: locale
}

export enum BusieLocale {
  EN_US = 'en-us',
  ES_MX = 'es-mx',
}
