import qs from 'qs'

import { dayjsExt, history } from '@busie/utils'

export const getFilterParams = (): {
  status: string
  searchParam: string
  searchValue: string
  locationId: string | 'ALL'
  pickupDateFrom: Date | null
  pickupDateTo: Date | null
} => {
  const searchParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })
  return {
    status: `${(searchParams.status as string) || 'ALL'}`,
    locationId: `${(searchParams.locationId as string) || 'ALL'}`,
    searchParam: `${searchParams.searchParam as string}` || '',
    searchValue: `${searchParams.searchValue as string}` || '',
    pickupDateFrom: searchParams.pickupDateFrom
      ? dayjsExt(`${searchParams.pickupDateFrom as string}`).toDate()
      : null,
    pickupDateTo: searchParams.pickupDateTo
      ? dayjsExt(`${searchParams.pickupDateTo as string}`).toDate()
      : null,
  }
}
