import { CustomPalette, styled, Theme } from '@mui/system'

interface Props {
  theme?: { palette: CustomPalette } & Theme
  fullwidth?: boolean
  isMobile?: boolean
  grow?: boolean

  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
}

const TableContainerStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'grow',
})<Props>((props) => {
  const { isMobile } = props
  const palette = props.theme?.palette
  return {
    background: isMobile ? 'transparent' : palette?.black?.plus100,
    width: '100%',
    maxWidth: props.fullwidth ? '' : '1360px',
    margin: '0 auto',
    boxShadow: isMobile
      ? 'none'
      : `inset 0px -1px 0px ${palette?.black?.plus70}, inset -1px 0px 0px ${palette?.black?.plus70}, inset 0px 1px 0px ${palette?.black?.plus70}, inset 1px 0px 0px ${palette?.black?.plus70}`,
    borderRadius: '4px',
    paddingTop: isMobile ? '0' : '12px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.justify || 'space-between',
    flexGrow: props.grow ? 1 : 0,
    '.table-container': {
      minHeight: '550px',
    },
    thead: {
      th: {
        padding: '8px 10px',
      },
    },
    tbody: {
      td: {
        padding: '8px 10px',
      },
    },
  }
})

export default TableContainerStyled
