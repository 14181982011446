import { CustomPalette, styled, Theme } from '@mui/system'
import { formControlLabelClasses } from '@mui/material/FormControlLabel'
import { typographyClasses } from '@mui/material/Typography'

import { RadioButtonGroup } from '@busie/ui-kit'
interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const RadioGroupStyled = styled(RadioButtonGroup)((props: Props) => {
  const palette = props.theme?.palette
  return {
    flexDirection: 'row',
    [`label.${formControlLabelClasses.root} .${typographyClasses.root}`]: {
      fontSize: '10px',
      color: palette?.black?.plus10,
    },
  }
})

export default RadioGroupStyled
