import { CustomPalette, styled } from '@mui/system'
import { inputLabelClasses } from '@mui/material/InputLabel'

import { Theme } from '@mui/system'
import { inputStyles } from '../../shared/inputStyles'

interface Props {
  theme?: Theme & { palette: CustomPalette }
}

const AmountFieldStyled = styled('div')((props: Props) => {
  const palette = props.theme?.palette
  return {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '80px',
    [`.${inputLabelClasses.root}`]: {
      fontSize: '14px',
      color: palette?.black?.plus20,
      fontWeight: '600',
    },
    textAlign: 'left',
    '.field': {
      display: 'inline-flex',
      alignItems: 'center',
      columnGap: '8px',
      button: {
        padding: '0',
        height: '24px',
        width: '24px',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        borderRadius: '3px',
        transition: 'background .2s ease',
        '&:hover, &:focus': {
          background: palette?.black?.plus80,
          outlineColor: palette?.black?.main,
        },
      },
      input: {
        ...inputStyles,
        borderColor: palette?.black?.plus10,
        '&.number-input': {
          width: '48px',
          textAlign: 'center',
          color: palette?.black?.main,
          WebkitTextFillColor: palette?.black?.main,
          fontWeight: '600',
          fontSize: '16px',
          padding: '1px 0',
          fontFamily: 'inherit',
          MozAppearance: 'textfield',
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },

          '&:focus': {
            outlineColor: palette?.black?.main,
          },
        },
      },
    },
  }
})

export default AmountFieldStyled
