export { default as ArmrestsIcon } from './armrests'
export { default as CustomIcon } from './custom'
export { default as DvdPlayerIcon } from './dvdPlayer'
export { default as FootrestsIcon } from './footrests'
export { default as HeadrestsIcon } from './headrests'
export { default as KneelingCoachIcon } from './kneelingCoach'
export { default as OutletsIcon } from './outlets'
export { default as RecliningSeatsIcon } from './recliningSeats'
export { default as RestroomIcon } from './restroom'
export { default as SeatbeltsIcon } from './seatbelts'
export { default as UsbPortsIcon } from './usbPorts'
export { default as WifiIcon } from './wifi'
export { default as Custom } from './custom'

export interface AmenityIconProps {
  color?: string
  size?: number
}
