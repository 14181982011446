import { observer } from 'mobx-react'
import React, { FC } from 'react'

import { ETPAmenity } from '@busie/api'
import { locale } from '@busie/utils'
import { StepName } from './FormBlock'
import { QuoteFormEmbedded } from './QuoteFormEmbedded'
import { QuoteFormWithAuth } from './QuoteFormWithAuth'

export interface QuoteFormProps {
  embedded?: boolean
  customersAuthToken?: string
  pathfinderAuthToken?: string
  tripsAuthToken?: string
  quotesAuthToken?: string
  rateServiceAuthToken?: string
  guestAuthToken?: string
  embeddedFormOrgId?: string
  stepList: StepName[]
  quoteId?: string
  useMetricSystem?: boolean
  locale?: locale
  displayedAmenities?: ETPAmenity[]
}

export const QuoteForm: FC<React.PropsWithChildren<QuoteFormProps>> = observer(
  (props) =>
    props.embedded ? (
      <QuoteFormEmbedded {...props} />
    ) : (
      <QuoteFormWithAuth {...props} />
    )
)
