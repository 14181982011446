import React from 'react'
import { H1, palette, H3, PaymentSuccessIcon } from '@busie/ui-kit'
import PaymentBoxStyled, {
  PaymentWrapperStyled,
} from './styled/PaymentBoxStyled'

export const PaymentSuccess = () => {
  return (
    <PaymentWrapperStyled>
      <PaymentBoxStyled>
        <PaymentSuccessIcon />
        <H1 sx={{ textAlign: 'center' }}>Thank you for your Payment!</H1>
        <H3
          color={palette.black.plus60}
          weight={500}
          sx={{ textAlign: 'center' }}
        >
          You will receive an email shortly confirming your <br /> payment. If
          you have any questions <br /> or experience any issues, please contact
          us at <br />{' '}
          <a href="mailto: support@getbusie.com">support@getbusie.com</a>
        </H3>
      </PaymentBoxStyled>
    </PaymentWrapperStyled>
  )
}
