import { CustomPalette } from '@mui/system'
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel'
import { Theme, SxProps, styled } from '@mui/material/styles'

interface Props extends FormControlLabelProps {
  theme?: Theme & { palette: CustomPalette }
  sx?: SxProps<Theme>
}

const RadioButtonStyled = styled(FormControlLabel)((props: Props) => {
  const palette = props.theme?.palette
  return {
    '.MuiFormControlLabel-label': {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.04em',
      color: palette?.black?.main,
    },
  }
})

export default RadioButtonStyled
