import React from 'react'
import { H1, PaymentErrorIcon, H3, palette } from '@busie/ui-kit'
import PaymentBoxStyled, {
  PaymentWrapperStyled,
} from './styled/PaymentBoxStyled'

export const PaymentCancel = () => {
  return (
    <PaymentWrapperStyled>
      <PaymentBoxStyled>
        <PaymentErrorIcon />
        <H1 sx={{ textAlign: 'center' }}>Payment Failed</H1>
        <H3
          color={palette.black.plus60}
          weight={500}
          sx={{ textAlign: 'center' }}
        >
          Please contact us at{' '}
          <a href="mailto: support@getbusie.com">support@getbusie.com</a>, and{' '}
          <br /> we'll get this resolved in no time
        </H3>
      </PaymentBoxStyled>
    </PaymentWrapperStyled>
  )
}
