import {
  AppState,
  Auth0Provider,
  withAuthenticationRequired,
} from '@auth0/auth0-react'
import { AuthTokensProvider, useFeatureFlags } from '@busie/core'
import { Loading } from '@busie/ui-kit'
import { env, history } from '@busie/utils'

import { enableLogging } from 'mobx-logger'
import React, { useEffect } from 'react'
import { BrowserRouter, Router } from 'react-router-dom'
import { CompatRouter, Navigate } from 'react-router-dom-v5-compat'
import '../shared/styles/index.css'
import { ApplicationCore } from './core'

const onRedirectCallback = (appState: AppState) => {
  return <Navigate to={appState?.returnTo || window.location.pathname} />
}

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const qs = window.location.search
  const urlParams = new URLSearchParams(qs)
  const invitation = urlParams.get('invitation') || undefined
  const organization = urlParams.get('organization') || undefined

  const CoreWithFeatureFlags = useFeatureFlags(ApplicationCore, {
    enabled: env('FEATURE_FLAGS_ENABLED')?.toLowerCase() === 'true',
    clientSideID: env('LAUNCH_DARKLY_CLIENT_ID'),
    streaming: env('LAUNCH_DARKLY_ENABLE_STREAMING')?.toLowerCase() === 'true',
  })

  const ProtectedCore = withAuthenticationRequired(CoreWithFeatureFlags, {
    onRedirecting: () => <Loading withLayout fillContainer />,
    loginOptions: {
      invitation,
      organization,
    },
  })

  useEffect(() => {
    enableMobxLogging()
  }, [])

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={env('BUSIE_AUTH_DOMAIN')}
        clientId={env('BUSIE_CLIENT_ID')}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <AuthTokensProvider>
          <Router history={history}>
            <CompatRouter>
              <ProtectedCore />
            </CompatRouter>
          </Router>
        </AuthTokensProvider>
      </Auth0Provider>
    </BrowserRouter>
  )
}

const enableMobxLogging = () => {
  const isDevelopment = process.env.NODE_ENV === 'development'
  const isLoggingDisabled =
    process.env.REACT_APP_DISABLE_MOBX_LOGGING?.toLowerCase() === 'true'

  const isLoggingEnabled = isDevelopment && !isLoggingDisabled
  if (isLoggingEnabled) {
    enableLogging({
      action: true,
      reaction: true,
      transaction: true,
      compute: true,
    })
  }
}
