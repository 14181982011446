import React, { forwardRef } from 'react'
import { NumberFormatValues } from 'react-number-format'
import NumberInputStyled from './styled/NumberInputStyled'

import InputLabel from '../InputLabel'

import { createHtmlAttrId } from '../helpers'
import { Box, SxProps, Theme } from '@mui/system'
import { NumberFormatProps } from 'react-number-format'
import { ErrorMessage } from '../typography'
import TextInputStyled from '../TextInput/styled/TextInputStyled'

export type NumberInputEvent = { target: { value: string } } //React.ChangeEvent<HTMLInputElement>;

export interface NumberInputProps extends NumberFormatProps {
  onChange: (event: NumberInputEvent) => void
  sx?: SxProps<Theme>
  wrapperSx?: SxProps<Theme>
  fullWidth?: boolean
  isAllowed?: (values: NumberFormatValues) => boolean
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>
  hideErrorSpace?: boolean
}
export const NumberInput: React.FC<React.PropsWithChildren<NumberInputProps>> =
  forwardRef((props, _) => {
    const createdId = `input-number-${createHtmlAttrId(
      (props.label as string) || props.placeholder
    )}`

    const getFormat = () => {
      if (props.value?.toString().startsWith('1')) {
        return '+# (###) ###-####'
      } else {
        if ((props.value?.toString().length || 0) > 12) {
          return '+###-###-#######'
        } else {
          return '+##-###-#######'
        }
      }
    }
    return (
      <Box
        sx={{
          ...props.wrapperSx,
          paddingTop: props.label ? '8px' : 0,
          width: props.fullWidth ? '100%' : 'inherit',
          height: props.label && !props.hideErrorSpace ? '90px' : 'auto',
        }}
      >
        {props.label && (
          <InputLabel required={props.required} htmlFor={props.id || createdId}>
            {props.label}
          </InputLabel>
        )}
        <NumberInputStyled
          id={props.id || createdId}
          isAllowed={props.isAllowed}
          disabled={props.disabled}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onValueChange={(values) => {
            props.onChange({ target: { value: values.value } })
          }}
          thousandSeparator={props.isMoneyField as boolean}
          allowNegative={!props.isMoneyField && props.allowNegative}
          prefix={props.isMoneyField ? '$' : props.prefix}
          suffix={props.suffix}
          format={props.isPhoneField ? getFormat() : props.format}
          isNumericString={true}
          customInput={TextInputStyled}
          getInputRef={props.inputRef}
          sx={props.sx}
          onBlur={props.onBlur}
        />
        {props.required && props.errorMessage && !!props.errorMessage && (
          <ErrorMessage>{props.errorMessage}</ErrorMessage>
          // eslint-disable-next-line indent
        )}
      </Box>
    )
  })
