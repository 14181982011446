import React from 'react'

import CustomerForm from '../../store/customerForm'
import FormContainer from '../FormContainer'
import PropertyString from '../PropertyString'
import EditButton from './EditButton'
interface Props {
  onEdit: () => void
  customerForm: CustomerForm
}

const CustomerInformationBlock: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const selectionMode =
    props.customerForm.selectionMode === 'create_new_customer'
      ? 'New customer'
      : 'Existing customer'
  const customer = props.customerForm.customer

  if (customer === null) return <> </>
  return (
    <FormContainer
      title="Customer Information"
      controls={
        <EditButton
          onClick={props.onEdit}
          aria-label="edit customer information"
        />
      }
    >
      <PropertyString name="Type">{selectionMode}</PropertyString>
      <PropertyString name="Full Name">{customer?.name}</PropertyString>
      <PropertyString name="Organization">
        {customer?.groupName ||
          props.customerForm.customerGroup?.name ||
          'No group'}
      </PropertyString>
      <PropertyString name="Email">{customer?.email}</PropertyString>
      <PropertyString name="Phone number">
        {`${customer?.areaCode ?? ''}${customer?.phoneNumber ?? ''}`}
      </PropertyString>
    </FormContainer>
  )
}

export default CustomerInformationBlock
