import { useAuth0 } from '@auth0/auth0-react'
import { User } from '@busie/utils'
import { useCallback, useEffect, useRef } from 'react'
import { queryClient, TOKEN_CACHE_CONFIG } from '../../../queries/client'
import { useAuthTokensContext } from '../../providers/AuthTokensProvider'
import { type Audience } from '../../providers/constants'
/**
 * Configuration interface for the logging system.
 * Controls logging behavior across different environments.
 */
interface LoggingConfig {
  enabled: boolean
  metrics: boolean
  level: 'debug' | 'info' | 'warn' | 'error'
  environments: string[]
}

/**
 * Interface for auth performance metrics tracking.
 */
interface AuthPerformanceMetrics {
  authAttempts: number
  successfulAuths: number
  failedAuths: number
  averageAuthTime: number
  tokenRequests: number
  lastAuthTimestamp: number
}

/**
 * Global performance metrics state.
 */
const performanceMetrics = {
  authAttempts: 0,
  successfulAuths: 0,
  failedAuths: 0,
  authTimes: [] as number[],
  tokenRequests: 0,
  lastAuthTimestamp: 0,
}

/**
 * Get a summary of auth performance metrics
 */
export const getAuthPerformanceSummary = (): AuthPerformanceMetrics => {
  const totalAuthTime = performanceMetrics.authTimes.reduce(
    (sum, time) => sum + time,
    0
  )
  const averageAuthTime =
    performanceMetrics.authTimes.length > 0
      ? totalAuthTime / performanceMetrics.authTimes.length
      : 0

  return {
    authAttempts: performanceMetrics.authAttempts,
    successfulAuths: performanceMetrics.successfulAuths,
    failedAuths: performanceMetrics.failedAuths,
    averageAuthTime,
    tokenRequests: performanceMetrics.tokenRequests,
    lastAuthTimestamp: performanceMetrics.lastAuthTimestamp,
  }
}

/**
 * Types for logging system messages and arguments.
 */
type LogMessage =
  | string
  | number
  | boolean
  | null
  | undefined
  | Error
  | unknown[]
  | Record<string, unknown>
  | unknown

type LogArgs = LogMessage[]

/**
 * Default logging configuration.
 * Enables logging only in development environment.
 */
const DEFAULT_LOGGING_CONFIG: LoggingConfig = {
  enabled: process.env.NODE_ENV === 'development',
  metrics: false,
  level: 'debug',
  environments: ['development'],
}

let loggingConfig = { ...DEFAULT_LOGGING_CONFIG }

// Logging override utilities
export const configureAuthLogging = (config: Partial<LoggingConfig>) => {
  loggingConfig = { ...loggingConfig, ...config }
}

export const resetAuthLogging = () => {
  loggingConfig = { ...DEFAULT_LOGGING_CONFIG }
}

const shouldLog = () => {
  return (
    loggingConfig.enabled ||
    loggingConfig.metrics ||
    loggingConfig.environments.includes(process.env.NODE_ENV || 'development')
  )
}

const log = {
  debug: (...args: LogArgs) => {
    if (shouldLog() && loggingConfig.level === 'debug') {
      console.log('🔐', ...args)
    }
  },
  info: (...args: LogArgs) => {
    if (shouldLog() && ['debug', 'info'].includes(loggingConfig.level)) {
      console.log('🔐', ...args)
    }
  },
  warn: (...args: LogArgs) => {
    if (
      shouldLog() &&
      ['debug', 'info', 'warn'].includes(loggingConfig.level)
    ) {
      console.warn('🔐', ...args)
    }
  },
  error: (...args: LogArgs) => {
    if (shouldLog()) {
      console.error('🔐', ...args)
    }
  },
  metric: (...args: LogArgs) => {
    if (loggingConfig.metrics) {
      console.log('🔐 [Metric]', ...args)
    }
  },
}

export interface UseAuthReturn {
  // User information
  user: User | undefined
  isLoading: boolean
  isAuthenticated: boolean

  // Token management (synchronous)
  getToken: (audience: Audience) => string
  getTokens: (audienceKeys: Audience[]) => Record<Audience, string>

  // Async prefetching
  prefetchToken: (audience: Audience) => Promise<void>
  prefetchTokens: (audienceKeys: Audience[]) => Promise<void>

  // Auth0 methods
  loginWithRedirect: ReturnType<typeof useAuth0>['loginWithRedirect']
  logout: ReturnType<typeof useAuth0>['logout']

  // Batch loading
  isBatchLoading: boolean
}

/**
/**
 * Unified authentication hook that combines Auth0 user management and token handling.
 *
 * @param {string} [label] - Optional label to identify the component using this hook
 * @returns {UseAuthReturn} Object containing user info, token management, and auth methods
 *
 * @example
 * ```tsx
 * const { user, getToken, isAuthenticated } = useAuth('MyComponent')
 * ```
 */
export const useAuth = (label?: string): UseAuthReturn => {
  const { user, isLoading, isAuthenticated, loginWithRedirect, logout } =
    useAuth0<User>()
  const { getToken, isBatchLoading } = useAuthTokensContext()
  const authStartTime = useRef<number>(0)
  const callerLabel = label || 'unknown component'

  // Log mount only once
  useEffect(() => {
    log.debug('🚌 useAuth mounted from:', callerLabel)
  }, [callerLabel])

  // Wrap getToken to add logging and metrics
  const getTokenWithLogging = useCallback(
    (audience: Audience): string => {
      log.debug(`🚌 Getting token for audience: ${audience} from:`, callerLabel)
      performanceMetrics.tokenRequests++
      log.metric('🚌 Token requested', { audience, caller: callerLabel })
      const token = getToken(audience)
      log.debug(`🚌 Token retrieved for audience: ${audience}`)
      return token
    },
    [getToken, callerLabel]
  )

  // Enhanced getTokens with React Query caching
  const getTokensWithCache = useCallback(
    (audienceKeys: Audience[]): Record<Audience, string> => {
      log.debug('🚌 Getting multiple tokens:', {
        audienceKeys,
        caller: callerLabel,
      })

      const tokens = audienceKeys.map((audience) => {
        // Check cache first
        const cachedToken = queryClient.getQueryData<string>([
          'auth-token',
          audience,
          user?.org_id,
        ])
        if (cachedToken) {
          log.debug(`🚌 Using cached token for ${audience}`)
          return { audience, token: cachedToken }
        }

        const token = getTokenWithLogging(audience)
        // Cache the token
        queryClient.setQueryData(['auth-token', audience, user?.org_id], token)
        return { audience, token }
      })

      log.metric('🚌 Tokens batch retrieved', {
        count: audienceKeys.length,
        caller: callerLabel,
      })

      return tokens.reduce(
        (acc, { audience, token }) => {
          acc[audience] = token
          return acc
        },
        {} as Record<Audience, string>
      )
    },
    [getTokenWithLogging, queryClient, user?.org_id, callerLabel]
  )

  // Pre-fetch token when requested
  const prefetchToken = useCallback(
    async (audience: Audience) => {
      return queryClient.prefetchQuery(
        ['auth-token', audience, user?.org_id],
        () => getTokenWithLogging(audience),
        TOKEN_CACHE_CONFIG
      )
    },
    [getTokenWithLogging, queryClient, user?.org_id]
  )

  // Pre-fetch multiple tokens
  const prefetchTokens = useCallback(
    async (audienceKeys: Audience[]): Promise<void> => {
      await Promise.all(audienceKeys.map((audience) => prefetchToken(audience)))
    },
    [prefetchToken]
  )

  // Track authentication attempts and timing
  useEffect(() => {
    if (isLoading) {
      authStartTime.current = Date.now()
      performanceMetrics.authAttempts++
      log.metric('Auth attempt started')
    } else if (isAuthenticated && user) {
      const authDuration = Date.now() - authStartTime.current
      performanceMetrics.authTimes.push(authDuration)
      performanceMetrics.successfulAuths++
      performanceMetrics.lastAuthTimestamp = Date.now()
      log.metric('Auth successful', {
        duration: authDuration,
        user: {
          email: user.email,
          org_id: user.org_id,
        },
      })
    } else if (!isLoading && !isAuthenticated) {
      performanceMetrics.failedAuths++
      log.metric('Auth failed')
    }
  }, [isLoading, isAuthenticated, user])

  // Log authentication state changes
  if (isLoading) {
    log.debug('Auth state loading...')
  } else if (isAuthenticated && user) {
    log.info('User authenticated:', {
      email: user.email,
      org_id: user.org_id,
      roles: user['https://getbusie.com/roles'],
    })
  } else if (!isAuthenticated) {
    log.warn('User not authenticated')
  }

  return {
    // User information
    user,
    isLoading,
    isAuthenticated,

    // Token management (synchronous with caching)
    getToken: useCallback(
      (audience: Audience) => {
        // Check cache first
        const cachedToken = queryClient.getQueryData<string>([
          'auth-token',
          audience,
          user?.org_id,
        ])
        if (cachedToken) {
          log.debug(`🚌 Using cached token for ${audience}`)
          return cachedToken
        }

        // Get fresh token and cache it
        const token = getTokenWithLogging(audience)
        queryClient.setQueryData(['auth-token', audience, user?.org_id], token)
        return token
      },
      [getTokenWithLogging, queryClient, user?.org_id]
    ),
    getTokens: getTokensWithCache,
    prefetchToken,
    prefetchTokens,

    // Auth methods
    loginWithRedirect: (...args) => {
      log.info('Redirecting to login...')
      log.metric('Login redirect initiated')
      return loginWithRedirect(...args)
    },
    logout: (...args) => {
      log.info('Logging out...')
      log.metric('Logout initiated')
      // Clear token cache on logout
      queryClient.clear()
      return logout(...args)
    },
    isBatchLoading,
  }
}
