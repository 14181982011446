import { format as dateFnsFormat } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BusieLocale,
  type UseLocaleProps,
  type UseLocaleReturn,
} from './localization.types'
import { type locale } from './localization.types'

const resolveLocale = (language: string): Locale =>
  language.startsWith('es') ? es : enUS

export const useLocale = ({
  defaultLocale = enUS,
  domain,
}: UseLocaleProps = {}): UseLocaleReturn => {
  const { useMetricSystem } = useFlags<{ useMetricSystem: boolean }>()

  const [locale, setLocale] = useState(() => {
    const storedLanguage = localStorage.getItem('lz_currentLanguage')
    return storedLanguage ? resolveLocale(storedLanguage) : defaultLocale
  })

  useEffect(() => {
    if (domain?.includes('com.mx')) setLocale(es)
  }, [domain])

  const resolvedLanguageCode: locale = useMemo(
    () => (locale.code === 'es' ? BusieLocale.ES_MX : BusieLocale.EN_US),
    [locale]
  )

  const formatDate = useCallback(
    (date: string, format: string): string =>
      dateFnsFormat(new Date(date), format, { locale }),
    [locale]
  )

  const usesMetricSystem = useMemo(() => {
    return locale.code === 'es' || useMetricSystem
  }, [locale, useMetricSystem])

  const { t } = useTranslation()

  return {
    locale,
    d: formatDate,
    t,
    usesMetricSystem,
    busieLocale: resolvedLanguageCode,
  }
}
